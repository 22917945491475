.ns-pages,
.ns-pages.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ns-pages.left {
    justify-content: flex-start;
}
.ns-pages.right {
    justify-content: flex-end;
}
.ns-pages ul {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.ns-pages ul li {
    width: auto;
    min-width: var(--button-height);
    height: var(--button-height);
}
.ns-pages ul li a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: var(--button-type-size);
    font-weight: var(--button-type-weight);
    color: var(--button-type-color);
    text-transform: var(--button-text-transform);
    text-decoration: none;
    border-radius: var(--button-border-radius);
    background: var(--button-background);
    overflow: hidden;
    letter-spacing: var(--button-letter-spacing);
    filter: none !important;
    vertical-align: middle;
    transition: var(--animation);
}
.ns-pages ul li.selected {
    width: auto;
    min-width: var(--button-height);
    height: var(--button-height);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: var(--button-type-size);
    font-weight: var(--selected-font-weight);
    color: var(--selected-font-color);
    border-radius: var(--button-border-radius);
    background: var(--selected-background);
}
.ns-pages ul li a > span {
    display: block;
    position: relative;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    letter-spacing: inherit;
    line-height:  var(--button-line-height);
    text-transform: inherit;
    text-decoration: none;
    letter-spacing: var(--button-letter-spacing);
    z-index: 3;
}
.ns-pages ul li.divider {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ns-pages ul li.divider:before {
    display: inline;
    content: "...";
}
.ns-pages ul li a:before {
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    position: absolute;
    top: var(--button-height);
    left: 0;
    z-index: 1;
    background: var(--button-background--hover);
    transition: var(--animation);
}
.ns-pages ul li a:hover {
    cursor: pointer;
    color: var(--button-type-color--hover);
}
.ns-pages ul li a:hover:before {
    top: 0px;
}
.ns-pages ul li a:hover:after {
    --chevron-color: var(--button-type-color--hover);
    z-index: 3;
}