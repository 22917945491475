/* Normalização das Dialogs do Jquery-UI */
.ui-dialog {
    position: relative;
    padding: 0 !important;
    border: none !important;
    background: transparent !important;
    z-index: var(--z-dialog);
}
.ui-dialog-titlebar {
    width: var(--card-close-size) !important;
    height: var(--card-close-size) !important;
    position: absolute !important;
    top: calc(var(--spacer)/3);
    right: calc(var(--spacer)/3);
    padding: 0 !important;
    border: none !important;
    background: transparent !important;
}
.ui-dialog-titlebar,
.ui-dialog-titlebar:after,
.ui-dialog-titlebar:before,
.ui-dialog-titlebar .ui-dialog-title,
.ui-button .ui-icon,
.ui-button .ui-button-icon-space {
    display: none !important;
}
/* Normalização do Carousel do Slick */
.ns-carousel .slick-list {
    grid-area: carousel;
}
.ns-carousel.nav .slick-prev {
    grid-area: prev;
}
.ns-carousel.nav .slick-next {
    grid-area: next;
}
.ns-carousel.nav-over .slick-prev {
    grid-area: carousel;
}
.ns-carousel.nav-over .slick-next {
    grid-area: carousel;
}
.ns-carousel.nav .slick-arrow {
    width: var(--carousel-chevron-size);
    height: calc(var(--carousel-chevron-size)*2 / 1.4);
    position: relative;
    overflow: hidden;
    color: transparent;
    display: inline-flex !important;
    align-self: center;
    background: transparent;
}
.ns-carousel.nav .slick-arrow:before {
    width: var(--carousel-chevron-size);
    height: var(--carousel-chevron-size);
    border-right: var(--carousel-chevron-thickness) solid var(--carousel-chevron-color);
    border-bottom: var(--carousel-chevron-thickness) solid var(--carousel-chevron-color);
    background: transparent;
    top: calc(var(--carousel-chevron-size) *0.21);
    left: calc(0px - var(--carousel-chevron-size) *0.21);
    transform: rotate(-45deg);
}
.ns-carousel.nav .slick-arrow:hover:before {
    border-right: var(--carousel-chevron-thickness) solid var(--carousel-chevron-color--hover);
    border-bottom: var(--carousel-chevron-thickness) solid var(--carousel-chevron-color--hover);
}
.ns-carousel.nav.nav-over .slick-arrow {
    position: absolute;
    top: calc((100% - calc(var(--carousel-chevron-size)*2 / 1.4))/2);
}
.ns-carousel.nav.nav-over .slick-prev {
    left: var(--spacer);
}
.ns-carousel.nav.nav-over .slick-next {
    right: var(--spacer);
}
.ns-carousel.nav .slick-prev:before {
    left: auto;
    right: calc(0px - var(--carousel-chevron-size) *0.21);
    transform: rotate(-225deg);
}
.ns-carousel.nav .slick-prev:after,
.ns-carousel.nav .slick-next:after {
    display: none;
}
.ns-carousel .slick-dots {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
}
.ns-carousel .slick-dots li {
    margin: 0 calc(var(--carousel-dots-size)/2) var(--carousel-dots-size);
}
.ns-carousel .slick-dots li button {
    --button-background: var(--carousel-dots-color);
    --button-background--hover: var(--carousel-dots-color--hover);

    width: var(--carousel-dots-size);
    height: var(--carousel-dots-size);
    display: block;
    overflow: hidden;
    padding: 0;
    border-radius: var(--carousel-dots-radius);
    color: transparent;
}
.ns-carousel .slick-dots li.slick-active button {
    --button-background: var(--carousel-dots-color--selected);
}
.ns-carousel .slick-dots li button:after {
    background: var(--carousel-dots-color)
}
.ns-carousel.dots .slick-dots {
    grid-area: dots;
    display: flex !important;
}
.ns-carousel.dots-over .slick-dots {
    grid-area: carousel;
    display: flex !important;
    position: absolute;
    bottom: var(--spacer);
}