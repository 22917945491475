.ips-examslist header {
    width: 100%;
    height: var(--box-header);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacer);
    border-top-left-radius: var(--base-radius);
    border-top-right-radius: var(--base-radius);
    border: 1px solid var(--color-gray-lighter);
    background: var(--color-gray-lightest-v2);
}
.ips-examslist header h2 {
    margin: 0;
    padding: 0 var(--spacer) 0 0;
    font-size: 1.375rem;
    font-weight: 400;
    color: var(--color-darkest);
}
.ips-examslist .group {
    margin: 0;
    padding: var(--spacer);
}
.ips-examslist .group h3 {
    margin: 0 0 calc(var(--spacer)/4);
    font-size: 1.175rem;
    font-weight: 600;
    color: var(--color-darkest);
}
.ips-examslist .group ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.ips-examslist .group ul li a {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.5rem;
    color: var(--color-darkest);
    text-decoration: none;
}