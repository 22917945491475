.ips-exam--item {
    padding: var(--spacer); 
    border-radius: var(--base-radius);
    border: 1px solid var(--color-gray-lighter);
}
.ips-exam--item header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}
.ips-exam--item header div {
    display: flex;
    justify-content: flex-start;
}
.ips-exam--item header h2 {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--color-darkest);
}
.ips-exam--item header .status {
    margin: 0 0 0 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 400;
    color: var(--color-darkest);
}
.ips-exam--item header .status img,
.ips-exam--item header .status .box {
    margin: 0 4px 0 0;
}
.ips-exam--item header .status .box {
    width: 24px;
    height: 24px;
    display: block;
    border-radius: 6px;
    border: 1px solid var(--color-gray-lighter);
}
.ips-exam--item header .ips-expand {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 calc(var(--spacer)/2) 0 var(--spacer);
}
.ips-exam--item header .ips-expand:before {
    width: 12px;
    height: 12px;
    display: block;
    content: "";
    margin-top: -5px;
    border-bottom: 1px solid var(--color-darkest);
    border-right: 1px solid var(--color-darkest);
    transform: rotate(45deg);
}
.ips-exam--item header .ips-expand.opened:before {
    margin-top: 5px;
    transform: rotate(225deg);
}
.ips-exam--item header .ns-btn-clear {
    --button-background: transparent;
    --button-background--hover: var(--color-gray-lightest-v2);
    --button-type-color--hover: var(--color-gray-darker);
    border: 1px solid var(--color-primary);
}
.ips-exam--item header .ns-btn-clear[disabled] {
    --button-background--hover: transparent;
}
.ips-exam--item .body {
    display: none;
}
.ips-exam--item .body dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    margin: 0;
    padding: var(--spacer) 0 0;
}
.ips-exam--item .body dl dt {
    padding: 0 0 8px;
    margin: 0 0 calc(var(--spacer));
    font-size: 1.125rem;
    font-weight: 500;
    border-bottom: 1px solid var(--color-gray-lighter);
}
.ips-exam--item .body dl dd {
    padding: 0 0 8px;
    margin: 0 0 calc(var(--spacer));
    font-size: 1.125rem;
    border-bottom: 1px solid var(--color-gray-lighter);
    text-align: right;
}
@media (max-width: 769px) {
    .ips-exam--item header {
        display: block;
    }
    .ips-exam--item header .actions {
        width: 100%;
        margin-top: calc(var(--spacer)/2);
        justify-content: space-between;
    }
}