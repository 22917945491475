.ips-vdisk--aside {
    border-radius: var(--base-radius);
    background: var(--color-primary-dark);
}
.ips-vdisk--aside header {
    width: 100%;
    height: var(--box-header);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacer);    
    border-bottom: 1px solid var(--color-lightest);
}
.ips-vdisk--aside header .title {
    margin: 0;
    padding: 0 var(--spacer) 0 0;
    font-size: 1.375rem;
    font-weight: 400;
    color: var(--color-lightest);
}
.ips-vdisk--aside .body {
    padding: var(--spacer);
}
.ips-vdisk--aside ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.ips-vdisk--aside ul li {
    margin: 0 0 4px;
}
.ips-vdisk--aside ul li a {
    text-decoration: none;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.875rem;
    color: var(--color-lightest);
}
.ips-vdisk--aside ul li a:before {
    width: 5px;
    height: 5px;
    display: block;
    margin: -1px 10px 0 0;
    content: "";
    border-bottom: 1px solid var(--color-lightest);
    border-right: 1px solid var(--color-lightest);
    transform: rotate(-45deg);
}
.ips-vdisk--aside ul ul {
    display: none;
    margin: 0 0 0 calc(var(--spacer)*0.75);
}