.ips-calendar--side header {
    width: 100%;
    height: var(--box-header);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacer);
    border-top-left-radius: var(--base-radius);
    border-top-right-radius: var(--base-radius);
    background: var(--color-gray-lighter);
}
.ips-calendar--side header h2 {
    margin: 0;
    padding: 0 var(--spacer) 0 0;
    font-size: 1.375rem;
    font-weight: 400;
    color: var(--color-darkest);
}
.ips-calendar--side {
    border-radius: var(--base-radius);
    background: var(--color-gray-lightest-v2);
    border: 1px solid var(--color-gray-lighter);
}
.ips-calendar--side .calendar {
    padding: var(--spacer) var(--spacer) 0;
}
.ips-calendar--side .events--container {
    padding: var(--spacer);
}
.ips-calendar--side .events--container .events {
    max-height: 350px;
    overflow: auto;
}
.ips-calendar--side .events--container .events > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.ips-calendar--side .events--container .events > ul > li,
.ips-calendar--side .events--container .events > ul > li .title {
    margin: 0;
    padding: 0;
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--color-darkest);
}
.ips-calendar--side .events--container .events > ul > li ul {
    margin: 0.5rem 0 1rem 0.5rem;
    color: var(--color-primary);
}
.ips-calendar--side .events--container .events > ul > li ul li a {
    font-size: 0.875rem;
    text-decoration: none;
    color: var(--color-primary);
}