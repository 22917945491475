.ips-activity--item {
    padding: var(--spacer); 
    border-radius: var(--base-radius);
    border: 1px solid var(--color-gray-lighter);
}
.ips-activity--item header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 8px;
    border-bottom: 1px solid var(--color-primary);
}
.ips-activity--item header div {
    display: flex;
    justify-content: flex-start;
}
.ips-activity--item header h2 {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--color-darkest);
}
.ips-activity--item header .status {
    margin: 0 0 0 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 400;
    color: var(--color-darkest);
}
.ips-activity--item header .status img,
.ips-activity--item header .status .box {
    margin: 0 4px 0 0;
}
.ips-activity--item header .status .box {
    width: 24px;
    height: 24px;
    display: block;
    border-radius: 6px;
    border: 1px solid var(--color-gray-lighter);
}
.ips-activity--item header dl {
    margin: 0 ;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.ips-activity--item header dl * {
    font-size: 1.125rem;
}
.ips-activity--item header dl > * {
    margin: 0 4px;
}
.ips-activity--item .body {
    display: grid;
    padding: 8px 0;
    grid-template-columns: 53% 1fr;
    gap: calc(var(--spacer)*5);
}
.ips-activity--item .body .title {
    display: block;
    margin: 0 0 4px;
    font-size: 0.875rem;
    font-weight: 500;
}
.ips-activity--item .body p {
    font-size: 0.875rem;
}
.ips-activity--item .answers ul {
    margin: 10px 0;
    padding: 0;
    list-style: none;
}
.ips-activity--item .answers ul li {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ips-activity--item .answers .ns-btn-clear {
    --button-background: transparent;
    --button-background--hover: var(--color-gray-lightest-v2);
    --button-type-color--hover: var(--color-gray-darker);
    border: 1px solid var(--color-gray-lighter);
}
.ips-activity--item .answers ul .ns-btn-clear {
    --button-background: transparent;
    width: 32px;
    height: 32px;
    padding: 0;
}
.ips-activity--item .answers span {
    font-size: 0.875rem;
}
.ips-activity--item .answers .noanswer {
    display: block;
    margin: 10px 0;
}
@media (max-width:992px) {
    .ips-activity--item header {
        display: block;
    }
    .ips-activity--item header > dl {
        margin-top: calc(var(--spacer)/2);
    }
    .ips-activity--item .body {
        gap: var(--spacer);
    }
}
@media (max-width:769px) {
    .ips-activity--item .body {
        grid-template-columns: 100%;
    }
}
@media (max-width:576px) {
    .ips-activity--item header > dl {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}