[class*="ns-container"] {
    width: 100%;
    max-width: calc(var(--breakpoint) - (2 * var(--spacer)));
    margin: 0 auto;
}
.ns-container--full {
    max-width: calc(100% - (var(--spacer) * 2));
}
.ns-grid {
    display: grid;
    grid-template-columns: repeat(var(--grid-columns),1fr);
    gap: var(--grid-spacer);
}
/* Breakpoint Extra Extra Large (xxl): desktops fullhd */
[data-layout*="all-0"] {display: none;}
[data-layout*="all-1"] {grid-column: span 1;}
[data-layout*="all-2"] {grid-column: span 2;}
[data-layout*="all-3"] {grid-column: span 3;}
[data-layout*="all-4"] {grid-column: span 4;}
[data-layout*="all-5"] {grid-column: span 5;}
[data-layout*="all-6"] {grid-column: span 6;}
[data-layout*="all-7"] {grid-column: span 7;}
[data-layout*="all-8"] {grid-column: span 8;}
[data-layout*="all-9"] {grid-column: span 9;}
[data-layout*="all-10"] {grid-column: span 10;}
[data-layout*="all-11"] {grid-column: span 11;}
[data-layout*="all-12"] {grid-column: span 12;}
[data-layout*="default-0"] {display: none;}
[data-layout*="default-1"] {grid-column: span 1;}
[data-layout*="default-2"] {grid-column: span 2;}
[data-layout*="default-3"] {grid-column: span 3;}
[data-layout*="default-4"] {grid-column: span 4;}
[data-layout*="default-5"] {grid-column: span 5;}
[data-layout*="default-6"] {grid-column: span 6;}
[data-layout*="default-7"] {grid-column: span 7;}
[data-layout*="default-8"] {grid-column: span 8;}
[data-layout*="default-9"] {grid-column: span 9;}
[data-layout*="default-10"] {grid-column: span 10;}
[data-layout*="default-11"] {grid-column: span 11;}
[data-layout*="default-12"] {grid-column: span 12;}
[data-layout*="xx-0"] {display: none;}
[data-layout*="xx-1"] {grid-column: span 1;}
[data-layout*="xx-2"] {grid-column: span 2;}
[data-layout*="xx-3"] {grid-column: span 3;}
[data-layout*="xx-4"] {grid-column: span 4;}
[data-layout*="xx-5"] {grid-column: span 5;}
[data-layout*="xx-6"] {grid-column: span 6;}
[data-layout*="xx-7"] {grid-column: span 7;}
[data-layout*="xx-8"] {grid-column: span 8;}
[data-layout*="xx-9"] {grid-column: span 9;}
[data-layout*="xx-10"] {grid-column: span 10;}
[data-layout*="xx-11"] {grid-column: span 11;}
[data-layout*="xx-12"] {grid-column: span 12;}

@media (max-width: 1400px) {
    /* Breakpoint Extra Large (xl): notebooks */    
    [data-layout*="default-0"] {display: none;}
    [data-layout*="default-1"] {grid-column: span 1;}
    [data-layout*="default-2"] {grid-column: span 2;}
    [data-layout*="default-3"] {grid-column: span 3;}
    [data-layout*="default-4"] {grid-column: span 4;}
    [data-layout*="default-5"] {grid-column: span 5;}
    [data-layout*="default-6"] {grid-column: span 6;}
    [data-layout*="default-7"] {grid-column: span 7;}
    [data-layout*="default-8"] {grid-column: span 8;}
    [data-layout*="default-9"] {grid-column: span 9;}
    [data-layout*="default-10"] {grid-column: span 10;}
    [data-layout*="default-11"] {grid-column: span 11;}
    [data-layout*="default-12"] {grid-column: span 12;}
    [data-layout*="xl-0"] {display: none;}
    [data-layout*="xl-1"] {grid-column: span 1;}
    [data-layout*="xl-2"] {grid-column: span 2;}
    [data-layout*="xl-3"] {grid-column: span 3;}
    [data-layout*="xl-4"] {grid-column: span 4;}
    [data-layout*="xl-5"] {grid-column: span 5;}
    [data-layout*="xl-6"] {grid-column: span 6;}
    [data-layout*="xl-7"] {grid-column: span 7;}
    [data-layout*="xl-8"] {grid-column: span 8;}
    [data-layout*="xl-9"] {grid-column: span 9;}
    [data-layout*="xl-10"] {grid-column: span 10;}
    [data-layout*="xl-11"] {grid-column: span 11;}
    [data-layout*="xl-12"] {grid-column: span 12;}
}
@media (max-width: 1200px) {
    /* Breakpoint Large (lg): notebooks e tablets */    
    [data-layout*="default-0"] {display: none;}
    [data-layout*="default-1"] {grid-column: span 2;}
    [data-layout*="default-2"] {grid-column: span 2;}
    [data-layout*="default-3"] {grid-column: span 3;}
    [data-layout*="default-4"] {grid-column: span 4;}
    [data-layout*="default-5"] {grid-column: span 4;}
    [data-layout*="default-6"] {grid-column: span 6;}
    [data-layout*="default-7"] {grid-column: span 6;}
    [data-layout*="default-8"] {grid-column: span 9;}
    [data-layout*="default-9"] {grid-column: span 9;}
    [data-layout*="default-10"] {grid-column: span 12;}
    [data-layout*="default-11"] {grid-column: span 12;}
    [data-layout*="default-12"] {grid-column: span 12;}
    [data-layout*="lg-0"] {display: none;}
    [data-layout*="lg-1"] {grid-column: span 1;}
    [data-layout*="lg-2"] {grid-column: span 2;}
    [data-layout*="lg-3"] {grid-column: span 3;}
    [data-layout*="lg-4"] {grid-column: span 4;}
    [data-layout*="lg-5"] {grid-column: span 5;}
    [data-layout*="lg-6"] {grid-column: span 6;}
    [data-layout*="lg-7"] {grid-column: span 7;}
    [data-layout*="lg-8"] {grid-column: span 8;}
    [data-layout*="lg-9"] {grid-column: span 9;}
    [data-layout*="lg-10"] {grid-column: span 10;}
    [data-layout*="lg-11"] {grid-column: span 11;}
    [data-layout*="lg-12"] {grid-column: span 12;}
}
@media (max-width: 992px) {
    /* Breakpoint Medium (md): tablets */    
    [data-layout*="default-0"] {display: none;}
    [data-layout*="default-1"] {grid-column: span 2;}
    [data-layout*="default-2"] {grid-column: span 2;}
    [data-layout*="default-3"] {grid-column: span 3;}
    [data-layout*="default-4"] {grid-column: span 4;}
    [data-layout*="default-5"] {grid-column: span 4;}
    [data-layout*="default-6"] {grid-column: span 6;}
    [data-layout*="default-7"] {grid-column: span 6;}
    [data-layout*="default-8"] {grid-column: span 9;}
    [data-layout*="default-9"] {grid-column: span 9;}
    [data-layout*="default-10"] {grid-column: span 12;}
    [data-layout*="default-11"] {grid-column: span 12;}
    [data-layout*="default-12"] {grid-column: span 12;}
    [data-layout*="md-0"] {display: none;}
    [data-layout*="md-1"] {grid-column: span 1;}
    [data-layout*="md-2"] {grid-column: span 2;}
    [data-layout*="md-3"] {grid-column: span 3;}
    [data-layout*="md-4"] {grid-column: span 4;}
    [data-layout*="md-5"] {grid-column: span 5;}
    [data-layout*="md-6"] {grid-column: span 6;}
    [data-layout*="md-7"] {grid-column: span 7;}
    [data-layout*="md-8"] {grid-column: span 8;}
    [data-layout*="md-9"] {grid-column: span 9;}
    [data-layout*="md-10"] {grid-column: span 10;}
    [data-layout*="md-11"] {grid-column: span 11;}
    [data-layout*="md-12"] {grid-column: span 12;}
}
@media (max-width: 768px) {
    /* Breakpoint Small (sm): tablets e smartphones */    
    [data-layout*="default-0"] {display: none;}
    [data-layout*="default-1"] {grid-column: span 3;}
    [data-layout*="default-2"] {grid-column: span 3;}
    [data-layout*="default-3"] {grid-column: span 3;}
    [data-layout*="default-4"] {grid-column: span 4;}
    [data-layout*="default-5"] {grid-column: span 4;}
    [data-layout*="default-6"] {grid-column: span 6;}
    [data-layout*="default-7"] {grid-column: span 6;}
    [data-layout*="default-8"] {grid-column: span 9;}
    [data-layout*="default-9"] {grid-column: span 9;}
    [data-layout*="default-10"] {grid-column: span 12;}
    [data-layout*="default-11"] {grid-column: span 12;}
    [data-layout*="default-12"] {grid-column: span 12;}
    [data-layout*="sm-0"] {display: none;}
    [data-layout*="sm-1"] {grid-column: span 1;}
    [data-layout*="sm-2"] {grid-column: span 2;}
    [data-layout*="sm-3"] {grid-column: span 3;}
    [data-layout*="sm-4"] {grid-column: span 4;}
    [data-layout*="sm-5"] {grid-column: span 5;}
    [data-layout*="sm-6"] {grid-column: span 6;}
    [data-layout*="sm-7"] {grid-column: span 7;}
    [data-layout*="sm-8"] {grid-column: span 8;}
    [data-layout*="sm-9"] {grid-column: span 9;}
    [data-layout*="sm-10"] {grid-column: span 10;}
    [data-layout*="sm-11"] {grid-column: span 11;}
    [data-layout*="sm-12"] {grid-column: span 12;}
}
@media (max-width: 576px) {
    /* Breakpoint Extra Small (xs): smartphones */
    [data-layout*="default-0"] {display: none;}
    [data-layout*="default-1"] {grid-column: span 3;}
    [data-layout*="default-2"] {grid-column: span 3;}
    [data-layout*="default-3"] {grid-column: span 3;}
    [data-layout*="default-4"] {grid-column: span 4;}
    [data-layout*="default-5"] {grid-column: span 4;}
    [data-layout*="default-6"] {grid-column: span 6;}
    [data-layout*="default-7"] {grid-column: span 6;}
    [data-layout*="default-8"] {grid-column: span 9;}
    [data-layout*="default-9"] {grid-column: span 9;}
    [data-layout*="default-10"] {grid-column: span 12;}
    [data-layout*="default-11"] {grid-column: span 12;}
    [data-layout*="default-12"] {grid-column: span 12;}    
    [data-layout*="xs-0"] {display: none;}
    [data-layout*="xs-1"] {grid-column: span 1;}
    [data-layout*="xs-2"] {grid-column: span 2;}
    [data-layout*="xs-3"] {grid-column: span 3;}
    [data-layout*="xs-4"] {grid-column: span 4;}
    [data-layout*="xs-5"] {grid-column: span 5;}
    [data-layout*="xs-6"] {grid-column: span 6;}
    [data-layout*="xs-7"] {grid-column: span 7;}
    [data-layout*="xs-8"] {grid-column: span 8;}
    [data-layout*="xs-9"] {grid-column: span 9;}
    [data-layout*="xs-10"] {grid-column: span 10;}
    [data-layout*="xs-11"] {grid-column: span 11;}
    [data-layout*="xs-12"] {grid-column: span 12;}
}