:root {

    /* USER CUSTOM: Fonte Referência */
    --type-font-family: Roboto, Helvetica, Arial, sans serif;
    --type-font-size: 16px;
    --type-font-weight: 400;
    --type-font-color: var(--color-gray-darker); 
    --type-letter-spacing: 0.5;
    --type-line-height: 1.2;   
    --type-text-align: justify; 
    --type-verical-align: baseline; 
    --list-style: none;

    /* Botão de Fechar */
    --close-size: 24px;
    --close-color: var(--type-font-color);

    /* Chevrons de Avançar e Voltar */
    --chevron-size: 18px;
    --chevron-thickness: 2px;
    --chevron-color: var(--type-font-color);

    /* Layout, Espaçamento */
    --spacer: 1.25rem;
    --grid-columns: 12;
    --grid-spacer: var(--spacer);

    /* USER CUSTOM: Paleta de Cores */
    --color-primary: #00ADEF;
    --color-primary-dark: #004E70;
    --color-primary-light: #EBFAFF;
    --color-secondary: #333A46;
    --color-secondary-dark: #1e2229;
    --color-secondary-light: #003F5726;
    --color-lightest: #fff;
    --color-gray-lightest: #efefef;
    --color-gray-lightest-v2: #ececec;
    --color-gray-lighter: #cdcdcd;
    --color-gray-light: #999999;
    --color-gray: #666666;
    --color-gray-dark: #5a5a5a;
    --color-gray-darker: #3a3a3a;
    --color-gray-darkest: #212121;
    --color-darkest: #000;
    --color-link: #4A8BC7;
    --color-primary--font: #ffffff;
    --color-primary--hover: #1e2229;
    --color-primary--hoverfont: #ffffff;
    --color-secondary--hover: #1e2229;
    --color-secondary--font: #000000;
    --color-secondary--hoverfont: #ffffff;
    --color-default: #1976D2;
    --color-default--font: #ffffff;
    --color-default--hover: #0D47A1;
    --color-default--hoverfont: #ffffff;
    --color-success: #AED581;
    --color-success--font: #000000;
    --color-success--hover: #33691E;
    --color-success--hoverfont: #ffffff;
    --color-danger: #EF9A9A;
    --color-danger--font: #B71C1C;
    --color-danger--hover: #B71C1C;
    --color-danger--hoverfont: #ffffff;
    --color-warning: #FFF176;
    --color-warning--font: #000000;
    --color-warning--hover: #FFD600;
    --color-warning--hoverfont: #000000;
    --color-info: #81D4FA;
    --color-info--font: #0277BD;
    --color-info--hover: #03A9F4;
    --color-info--hoverfont: #ffffff;
    --color-light: #EEEEEE;
    --color-light--font: #212121;
    --color-light--hover: #9E9E9E;
    --color-light--hoverfont: #212121;
    --color-dark: #212121;
    --color-dark--font: #EEEEEE;
    --color-dark--hover: #000000;
    --color-dark--hoverfont: #EEEEEE;

    /* Normalize */
    --margin-top: 0;
    --margin-left: 0;
    --margin-right: 0;
    --margin-bottom: 0;
    --padding-top: 0;
    --padding-left: 0;
    --padding-right: 0;
    --padding-bottom: 0;

    /* Borders */
    --border-width: 0;
    --border-style: solid;
    --border-color: var(--type-font-color);
    --border: var(--border-width) var(--border-style) var(--border-color);

    /* Botões */
    --button-height: 36px;
    --button-border-radius: 4px;
    --button-type-size: 0.85rem;
    --button-icon-size: 1.2rem;
    --button-type-weight: 500;
    --button-type-color: var(--color-gray-dark);
    --button-type-color--hover: var(--color-lightest);
    --button-background: var(--color-gray-lightest); 
    --button-background--hover: var(--color-gray-light);
    --button-height-lg: 48px;
    --button-type-size-sm: 1rem;
    --button-icon-size-sm: 1rem;
    --button-height-sm: 28px;
    --button-type-size-sm: 0.75rem;
    --button-icon-size-lg: 1.4rem;

    /* Formulários */
    --form-height: 56px;
    --form-border-size: 1px;
    --form-border-radius: 4px;
    --form-font-size: 1rem;
    --form-font-size-activity: 0.7rem;
    --form-input-color: var(--color-gray-dark);
    --form-color: var(--color-gray-light);
    --form-color-activity: var(--color-primary);
    --form-color-placeholder: var(--color-gray-light);
    --form-background: var(--color-gray-lightest);
    --form-background-activity: var(--color-gray-lightest-v2);
    --form-tip-font-size: 0.75rem;
    --form-tip-font-weght: 300;
    --form-tip-font-style: italic;
    --form-tip-color: var(--color-gray-light);
    --form-placeholder-color: var(--color-gray-lighter);
    --form-question-font-size: 0.85rem;
    --form-question-font-weight: 500;
    --form-checkbox-size: 1rem;
    --form-checkbox-radius: 2px;
    --form-checkbox-thickness: 2px;
    --form-checkbox-mark-thickness: 3px;
    --form-checkbox-mark-color: var(--color-lightest);
    --form-checkbox-selected-color: var(--color-primary);
    --form-radio-size: 1rem;
    --form-radio-thickness: 2px;
    --form-radio-mark-size: 0.75rem;
    --form-radio-mark-color: var(--color-primary);
    --form-icon-size: 1.6rem;

    /* Tabelas */
    --table-border-outside-thickness: 2px;
    --table-border-outside-color: var(--color-gray-light);
    --table-border-header-thickness: 1px;
    --table-border-header-color: var(--color-gray-light);
    --table-border-footer-thickness: 2px;
    --table-border-footer-color: var(--color-gray-light);
    --table-background-header: var(--color-gray-lighter);
    --table-border-thickness: 1px;
    --table-border-color: var(--color-gray-lighter);
    --table-font-color: var(--color-gray-dark);
    --table-font-weight: 400;
    --table-font-header-color: var(--color-gray-darker);
    --table-font-header-weight: 500;
    --table-font-footer-color: var(--color-gray-darker);
    --table-font-footer-weight: 500;
    --table-background-even: var(--color-gray-lightest);
    --table-background-odd: var(--color-lightest);
    --table-background-hover: var(--color-warning);

    /* Cards, Caixas */
    --card-border-thickness: 1px;
    --card-border-color: var(--color-gray-lighter);
    --card-border-radius: 4px;
    --card-background: var(--color-lightest);
    --card-spacer: var(--spacer);
    --card-title-size: 1rem;
    --card-title-weight: 500;
    --card-close-size: 1rem;
    --card-close-color: var(--card-border-color);

    /* Paginação */
    --button-type-color: var(--color-gray-dark);
    --button-type-color--hover: var(--color-lightest);
    --button-background: var(--color-gray-lightest); 
    --button-background--hover: var(--color-gray-light);
    --selected-background: var(--color-primary);
    --selected-font-weight: 500;
    --selected-font-color: var(--color-lightest);
    --type-font-color: var(--color-gray);

    /* Modais */
    --dialog-border-thickness: 1px;
    --dialog-border-color: var(--color-gray-lighter);
    --dialog-border-radius: 4px;
    --dialog-background: var(--color-lightest);
    --dialog-spacer: var(--spacer);
    --dialog-title-size: 1rem;
    --dialog-title-weight: 500;
    --dialog-close-size: 1rem;
    --dialog-close-color: var(--card-border-color);

    /* Menu (Dropdown) */
    --dropdown-chevron-size: 0.4rem;
    --dropdown-chevron-thickness: 2px;
    --dropdown-chevron-color: var(--type-font-color);

    /* Menu */
    --menu-border-thickness: 1px;
    --menu-border-color: var(--color-gray-lighter);
    --menu-border-radius: 4px;
    --menu-background: var(--color-lightest);
    --menu-spacer: var(--spacer);
    --menu-font-size: 0.875rem;
    --menu-font-weight: 400;
    --menu-title-color : var(--color-gray-darker);
    --menu-title-weight : 500;
    --menu-icon-mdi: 1.2rem;
    --menu-icon-size: 24px;
    --menu-font-color--hover: var(--color-darkest);
    --menu-background--hover: var(--color-primary-light);

    /* Carousel */
    --carousel-chevron-size: 48px;
    --carousel-chevron-thickness: var(--chevron-thickness);
    --carousel-chevron-color: var(--chevron-color);
    --carousel-chevron-color--hover: var(--color-primary);
    --carousel-dots-size: 8px;
    --carousel-dots-radius: 0px;
    --carousel-dots-color: var(--color-gray-lighter);
    --carousel-dots-color--hover: var(--color-primary);
    --carousel-dots-color--selected: var(--color-primary-light);
}

/* Breakpoints */
:root {
    /* Breakpoint Extra Extra Large (xxl): desktops fullhd */
    --breakpoint: 1400px;
}
@media (max-width: 1400px) {
    /* Breakpoint Extra Large (xl): notebooks */
    :root {
        --breakpoint: 1200px;
    }
}
@media (max-width: 1200px) {
    /* Breakpoint Large (lg): notebooks e tablets */
    :root {
        --breakpoint: 992px;
    }
}
@media (max-width: 992px) {
    /* Breakpoint Medium (md): tablets */
    :root {
        --breakpoint: 768px;
    }
}
@media (max-width: 768px) {
    /* Breakpoint Small (sm): tablets e smartphones */
    :root {
        --breakpoint: 576px;
    }
}
@media (max-width: 576px) {
    /* Breakpoint Extra Small (xs): smartphones */
    :root {
        --breakpoint: 100%;
    }
}