.ips-footer .footer-main {
    display: flex;
    padding: 24px 80px;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid var(--color-gray-lighter);
    border-left: 0;
    border-right: 0;
}
.ips-footer .footer-main .shortcuts .title {
    display: block;
    padding: 0 0 0.75rem;
    font-size: 0.875rem;
    font-weight: 700;
}
.ips-footer .footer-main .shortcuts ul {
    max-width: 402px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 90px;
    row-gap: 0.75rem;
    list-style: none;
    margin: 0;
    padding: 0;
}
.ips-footer .footer-main .shortcuts ul li,
.ips-footer .footer-main .shortcuts ul li a {
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: none;
    text-align: left;
    color: var(--color-darkest);
}
.ips-footer .footer-main .signinsocial {
    max-width: 400px;
}
.ips-footer .footer-main .signinsocial > span {
    display: block;
    padding: 0 0 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--color-gray-darker);
}
.ips-footer .footer-main .signinsocial .ns-btn {
    align-self: stretch;
    --button-height: var(--form-height);
}
.ips-footer .footer-main .signinsocial ul {
    margin: 24px 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ips-footer .footer-extras {
    padding: var(--spacer);
    display: flex;
    align-items: center;
    justify-content: center;
}
.ips-footer .footer-extras a {
    margin: 0 48px;
    font-size: 0.75rem;
    color: var(--color-gray-darkest);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-decoration: none;
    font-weight: 500;
    text-align: center;
}
@media (max-width: 1200px) {
    .ips-footer .footer-main .shortcuts ul {
        grid-template-columns: 100%;
    }
}
@media (max-width: 992px) {
    .ips-footer .footer-main {
        display: block;
    }
    .ips-footer .footer-main .shortcuts {
        padding-bottom: calc(var(--spacer)*2);
    }
    .ips-footer .footer-main .shortcuts ul {
        grid-template-columns: auto auto;
    }
    .ips-footer .footer-main .shortcuts ul,
    .ips-footer .footer-main .signinsocial {
        max-width: 100%;
    }
    .ips-footer .footer-extras a {
        margin: 0 var(--spacer);
    }
}
@media (max-width: 576px) {
    .ips-footer .footer-main {
        padding: var(--spacer);
    }
    .ips-footer .footer-main .shortcuts ul {
        grid-template-columns: 100%;
    }
    .ips-footer .footer-extras {
        flex-wrap: wrap;
    }
    .ips-footer .footer-extras a {
        margin: 0 var(--spacer) var(--spacer);
    }
}