.ns-card * {
    --close-color: var(--card-close-color);
    --close-size: var(--card-close-size);
}
.ns-card {
    align-self: start;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: "header" "body" "footer";
    border: var(--card-border-thickness) solid var(--card-border-color);
    border-radius: var(--card-border-radius);
    overflow: hidden;
    background: var(--card-background);
}
.ns-card.footer-false {
    grid-template-rows: auto 1fr;
    grid-template-areas: "header" "body";
}
.ns-card.footer-false > footer {
    display: none;
}
.ns-card.header-false {
    grid-template-rows: 1fr auto;
    grid-template-areas: "body" "footer";
}
.ns-card.header-false.footer-false {
    grid-template-rows: auto;
    grid-template-areas: "body";
}
.ns-card.header-false > header {
    display: none;
}
.ns-card > header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.ns-card > header,
.ns-card > header > span {
    font-size: var(--card-title-size);
    font-weight: var(--card-title-weight);
}
.ns-card > header h1,
.ns-card > header h2,
.ns-card > header h3,
.ns-card > header h4,
.ns-card > header h5,
.ns-card > header h6 {
    margin: 0;
}
.ns-card > header > a.ns-close {
    margin-top: calc(0px - var(--spacer)/3);
    margin-right: calc(0px - var(--spacer)/3);
}
.ns-card > footer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ns-card > footer > * {
    margin: 0 calc(var(--card-spacer)/2);
}
.ns-card.footer-right > footer {
    justify-content: flex-end;
}
.ns-card.footer-left > footer {
    justify-content: flex-start;
}