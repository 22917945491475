.ips-mainmenu {
    grid-area: menu;
    width: 100%;
    height: var(--menu-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacer);
    background: var(--color-secondary);
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-mainmenu);
}
.ips-mainmenu .left-container,
.ips-mainmenu .right-container {
    display: flex;
    justify-self: flex-start;
    align-items: center;
}
.ips-mainmenu .left-container > a > img {
    width: 160px;
    height: 70px;
    object-fit: contain;
    object-position: center;
}
.ips-mainmenu .left-container > ul {
    list-style: none;
    display: flex;
    justify-self: flex-start;
    align-items: center;
}
.ips-mainmenu .left-container > ul > li {
    margin: 0 10px;
}
.ips-mainmenu .left-container > ul > li > a {
    display: flex;
    text-decoration: none;
    padding: 5px;
    border-bottom: 1px solid transparent;
}
.ips-mainmenu .left-container > ul > li > a * {
    color: #fff;
}
.ips-mainmenu .left-container .ns-dropdown > a:after {
    --dropdown-chevron-size: 0.4rem;
    --dropdown-chevron-thickness: 2px;
    --dropdown-chevron-color: var(--color-primary);
}
.ips-mainmenu .left-container .ns-dropdown .ns-menu {
    min-width: 224px;
}
.ips-mainmenu .left-container .ns-dropdown .ns-menu .more a {
    --padding-top: calc(var(--spacer)/2);
    --type-font-color: var(--color-primary);
    --type-text-align: right;
    border-top: 1px solid var(--color-gray-lighter);
    justify-content: flex-end;
}
.ips-mainmenu .right-container #notifications {
    margin: 0 var(--spacer) 0 0;
}
.ips-mainmenu .right-container #notifications .total {
    background-color: white;
    color: black;
    padding: 5px;
    font-size: 10px;
    position: absolute;
    left: 16px;
    top: 16px;
    border-radius: 5px;
}
.ips-mainmenu .right-container #notifications > a:after {
    display: none;
}
.ips-mainmenu .right-container #notifications.ns-dropdown {
    z-index: 110;
}
.ips-mainmenu .right-container #notifications.ns-dropdown .ns-menu {
    min-width: 300px;
    right: -40px;
}
.ips-mainmenu .right-container .ns-dropdown .ns-menu .more a {
    --padding-top: calc(var(--spacer)/2);
    --type-font-color: var(--color-primary);
    --type-text-align: right;
    border-top: 1px solid var(--color-gray-lighter);
    justify-content: flex-end;
}
.ips-mainmenu .right-container .ns-dropdown .ns-menu .title {
    --margin-top: calc(0px - var(--spacer)/4);
    --padding-top: calc(var(--spacer)/2);
    background: var(--color-gray-lightest);
    text-transform: uppercase;
}
.ips-mainmenu .right-container,
.ips-mainmenu .right-container #user {
    height: calc(var(--spacer)*2);
}
.ips-mainmenu .right-container #user .avatar {
    width: calc(var(--spacer)*2);
    height: calc(var(--spacer)*2);
    border-radius: 50%;
    border: 1px solid var(--color-primary);
    overflow: hidden;
}
.ips-mainmenu .right-container #user > a {
    text-decoration: none;
}
.ips-mainmenu .right-container #user > a * {
    color: #fff;
}
.ips-mainmenu .right-container .ns-dropdown > a:after {
    --dropdown-chevron-size: 0.4rem;
    --dropdown-chevron-thickness: 2px;
    --dropdown-chevron-color: var(--color-lightest);
}
.ips-mainmenu .right-container #user.ns-dropdown .ns-menu {
    min-width: 224px;
    right: 20px;
}
/* Seletor */
body#meu-espaco_index #meuespaco a,
body#meu-espaco_agenda #agenda a,
body#meu-espaco_disco-virtual #discovirtual a,
body#meu-espaco_trabalhos #trabalhos a,
body#meu-espaco_avaliacoes #avaliacoes a,
body.area_community #disciplinas a {
    border-bottom: 1px solid var(--color-primary);
}
@media (max-width: 1200px) {    
    .ips-mainmenu .right-container #user .name {
        display: none;
    }
}
@media (max-width: 992px) {    
    .ips-mainmenu .left-container > ul {
        --margin-left: var(--spacer);
        --padding-left: 0;
    }
    .ips-mainmenu .left-container > ul > li {
        margin: 0 5px;
    }
}

#notifications .ns-menu ul li a:after {
    content: "X";
    position: absolute;
    right: 15px;
    margin-top: -6px;
}
#notifications .ns-menu ul li a {
    margin: 8px;
    border: 1px solid #ececec;
    background-color: #ececec6b;
    padding: 11px;
}
#notifications .ns-menu ul li a {
    align-items: end;
    flex-flow: column;
}
#notifications .ns-menu ul li a span.info
{
    font-size: 9px;
    text-align: right;
}