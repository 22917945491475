.ips-communitylist header {
    width: 100%;
    height: var(--box-header);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top-left-radius: var(--base-radius);
    border-top-right-radius: var(--base-radius);
    background: url("/alunos/data/clients/1/images/disciplinas--header.jpg") right center no-repeat;
    background-size: cover;
}
.ips-communitylist header h2 {
    margin: 0;
    padding: 0 var(--spacer);
    font-size: 1.375rem;
    font-weight: 500;
    color: var(--color-lightest);
}
.ips-communitylist ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.ips-communitylist ul li {
    min-height: 152px;
    display: grid;
    grid-template-columns: minmax(26%,272px) 1fr;
    padding: 0;
    margin: calc(var(--spacer) * 1.2) 0;
    border: 1px solid var(--color-gray-lighter);
    border-radius: var(--base-radius);
    overflow: hidden;
}
.ips-communitylist ul li.notFound
{
    text-align: center;
    width: 100%;
    grid-template-columns: 1fr;
    padding: 20px;
    border: 0px;
    min-height: 40px;
    vertical-align: middle;
}
.ips-communitylist ul li a.image {
    width: 100%;
    height: 100%;
    display: grid;
    padding: 0;
}
.ips-communitylist ul li a.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.ips-communitylist ul li a.content {
    margin: 0;   
    padding: var(--spacer) calc(var(--spacer)*1.75);
    display: grid;
    grid-template-columns: 1fr 35%;
    gap: calc(var(--spacer)/2);
    grid-template-areas: "title title" "description description" "author author" "extras progress";
    text-decoration: none;
}
.ips-communitylist ul li .content .title {
    grid-area: title;
    display: block;
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-darkest);
}
.ips-communitylist ul li .content .description {
    grid-area: description;
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--color-gray);
}
.ips-communitylist ul li .content .extras {
    grid-area: extras;
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--color-gray);
}
.ips-communitylist ul li .content .author {
    grid-area: author;
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--color-darkest);
}
.ips-communitylist ul li .content .progress {
    display: grid;
    grid-area: progress;
    grid-template-columns: 1fr minmax(100px, auto);
    grid-template-areas: "graph info";
    gap: calc(var(--spacer)*1.5);
}
.ips-communitylist ul li .content .progress .info {
    grid-area: info;
    display: block;
    align-self: center;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--color-darkest);
}
.ips-communitylist ul li .content .progress .graph {
    grid-area: graph;
    width: 100%;
    height: 4px;
    display: block;
    position: relative;
    align-self: center;
    border-radius: 2px;
    overflow: hidden;
    background: var(--color-gray-lighter);
}
.ips-communitylist ul li .content .progress .graph .mark {
    height: 4px;
    display: block;
    position: relative;
    align-self: center;
    border-radius: 2px;
    overflow: hidden;
    background: var(--color-primary);
}
@media (max-width: 1400px) {
    .ips-communitylist ul li a.content {
        grid-template-columns: 1fr 50%;
    }
}
@media (max-width: 1200px) {
    .ips-communitylist ul li a.content {
        grid-template-areas: "title title" "description description" "author author" "extras extras" "progress progress";
    }
}
@media (max-width: 992px) {
    .ips-communitylist ul li a.content {
        grid-template-areas: "title title" "description description" "author author" "extras extras" "progress progress";
    }
    .ips-communitylist ul li {
        grid-template-columns: 1fr;
    }
}
[data-filter="started"] .notstarted {
    display: none;
}
[data-filter="notstarted"] .started {
    display: none;
}