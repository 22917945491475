/* GERAL */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, main {
    margin: var(--margin-top) var(--margin-right) var(--margin-bottom) var(--margin-left);
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    border: var(--border);
    font-family: var(--type-font-family);
    font-size: var(--type-font-size);
    font-style: var(--type-font-style);
    font-weight: var(--type-font-weight);
    color: var(--type-font-color);
    line-height: var(--type-line-height);
    list-style: var(--list-style);
    letter-spacing: var(--type--letter-spacing);
    text-align: var(--type-text-align);
    vertical-align: var(--type-verical-align);
}
/* Tipografia */
[class*="ns-title-"] {
    display: block;
}
.ns-subtitle,.ns-subtitle-1,.ns-subtitle-2 {
    display: block;
}
a {
    color: var(--color-link);
    text-decoration: underline;
}
a:hover {
    cursor: pointer;
    filter:brightness(0.6);
}
code {
    display: block;
    font-family: monospace;
    font-size: 0.875rem !important;
    color: var(--color-darkest);
    padding: var(--spacer);
    margin: 0 0 var(--spacer);
    background: var(--color-gray-lightest);    
    white-space: pre-wrap;
}
code * {
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 0.875rem !important;
    font-family: monospace !important;
    font-weight: 400 !important;
    font-style: normal !important;    
    line-height: 0 !important;
    white-space: pre;
    color: var(--color-darkest) !important;
}
.sr-only {
    display: none;
}
/* Elevação */
.level-1 {
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.20);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.20);
}
.level-2 {
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.20);
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.20);
}
.level-3 {
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.20);
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.20);
}
.level-4 {
    box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.20);
    -webkit-box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.20);
}
.level-5 {
    box-shadow: 0px 12px 8px 0px rgba(0,0,0,0.20);
    -webkit-box-shadow: 0px 12px 8px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 12px 8px 0px rgba(0,0,0,0.20);
}
.level-6 {
    box-shadow: 0px 16px 8px 0px rgba(0,0,0,0.20);
    -webkit-box-shadow: 0px 16px 8px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 16px 8px 0px rgba(0,0,0,0.20);
}
.level-7 {
    box-shadow: 0px 24px 8px 0px rgba(0,0,0,0.20);
    -webkit-box-shadow: 0px 24px 8px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 24px 8px 0px rgba(0,0,0,0.20);
}
/* Elementos Adicionais */
.ns-close {
    width: var(--close-size);
    height: var(--close-size);
    display: block;
    position: relative;
}
.ns-close:before {
    width: var(--close-size);
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    top: calc(var(--close-size) / 2);
    background: var(--close-color);
    transform: rotate(45deg);
}
.ns-close:after {
    width: var(--close-size);
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    top: calc(var(--close-size) / 2);
    background: var(--close-color);
    transform: rotate(-45deg);
}
.ns-prev,
.ns-next {
    width: var(--chevron-size);
    height: var(--chevron-size);
    display: block;
    position: relative;
}
.ns-prev:after,
.ns-next:after {
    width: var(--chevron-size);
    height: var(--chevron-size);
    display: block;
    content: "";
    position: absolute;
    border-right: var(--chevron-thickness) solid var(--chevron-color);
    border-bottom: var(--chevron-thickness) solid var(--chevron-color);
    transform: rotate(-45deg);
}
.ns-next:after {
    margin-left: calc(0px - (var(--chevron-size)/1.4142/2));
}
.ns-prev:after {
    margin-left: auto;
    margin-right: calc(0px - (var(--chevron-size)/1.4142/2));
    transform: rotate(135deg);
}
#agenda-container .calendar div.ui-datepicker {
    width: 100%;
    padding: 0px;
    border: 0px;
    background-color: transparent;
}
#agenda-container .calendar .ui-datepicker td
{
    padding: 0px;
}
#agenda-container .ui-datepicker .ui-datepicker-header
{
    background-color: transparent;
    border: 0px;
}
#agenda-container  .ui-datepicker .ui-datepicker-title
{
    text-align: left;
    margin: 0 2.3em 0 0 ;
}
#agenda-container  .ui-datepicker .ui-datepicker-title span
{
    font-weight: 500;
    font-size: 17px;
    color: black;
}
#agenda-container .calendar .ui-state-default
{
    background-color: transparent;
    color: black;
}
#agenda-container .ui-datepicker table
{
    font-size: 12px;
}
#agenda-container .ui-datepicker table th span
{
    font-weight: 500;
    color: black;   
    font-size: 14px;
}
#agenda-container .ui-datepicker td span, .ui-datepicker td a {
    padding: 0.5em;
    text-align: center;
    font-size: 14px;
}
#agenda-container .ui-datepicker .ui-datepicker-prev
{
    left: auto;
    right: 40px;
}

#agenda-container .ui-state-highlight
{
    background-color: #00ADEF !important;
    color: white !important;
    border: 3px solid  #00ADEF !important;
    padding: 5px;
}
#agenda-container .ui-state-active
{
    border: 3px solid #007fff;
    padding: 5px;
}
body#meu-espaco_index .ips-header > .ns-container {
    justify-content: space-between;
}
