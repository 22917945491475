.ns-dialog * {
    --close-color: var(--dialog-close-color);
    --close-size: var(--dialog-close-size);
}
.ns-dialog {
    align-self: start;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: "header" "body" "footer";
    padding: 0  !important;
    border: var(--dialog-border-thickness) solid var(--dialog-border-color) !important;
    border-radius: var(--dialog-border-radius) !important;
    background: var(--dialog-background) !important;
}
.ns-dialog.footer-false {
    grid-template-rows: auto 1fr;
    grid-template-areas: "header" "body";
}
.ns-dialog.footer-false > footer {
    display: none;
}
.ns-dialog.header-false {
    grid-template-rows: 1fr auto;
    grid-template-areas: "body" "footer";
}
.ns-dialog.header-false > header {
    display: none;
}
.ns-dialog > header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.ns-dialog > header,
.ns-dialog > header > span {
    font-size: var(--dialog-title-size);
    font-weight: var(--dialog-title-weight);
}
.ns-dialog > header > a.ns-close {
    margin-top: calc(0px - var(--spacer)/3);
    margin-right: calc(0px - var(--spacer)/3);
}
.ns-dialog > footer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ns-dialog > footer > * {
    margin: 0 calc(var(--dialog-spacer)/2);
}
.ns-dialog.footer-right > footer {
    justify-content: flex-end;
}
.ns-dialog.footer-left > footer {
    justify-content: flex-start;
}