/* Camadas */
:root {
    --z-default: 1;
    --z-card: 10;
    --z-menu: 100;
    --z-fab: 1000;
    --z-mainmenu: 10000;
    --z-dialog: 100000;
}
/* Tipografia */
* {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}
h1,.ns-title--main,.ns-title-1,h1 *,.ns-title--main *,.ns-title-1 *  {
    --type-font-size: 2.5rem;
    --type-font-weight: 300;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --margin-bottom: calc(var(--spacer) * 1);
}
h2,.ns-title,.ns-title-2,h2 *,.ns-title *,.ns-title-2 * {
    --type-font-size: 2rem;
    --type-font-weight: 300;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --margin-top: calc(var(--spacer) * 2);
    --margin-bottom: calc(var(--spacer) * 0.5);
}
h3,.ns-title-3,h3 *,.ns-title-3 * {
    --type-font-size: 1.75rem;
    --type-font-weight: 400;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --margin-top: calc(var(--spacer) * 1.5);
    --margin-bottom: calc(var(--spacer) * 0.5);
} 
h4,.ns-title-4,h4 *,.ns-title-4 * {
    --type-font-size: 1.5rem;
    --type-font-weight: 400;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --margin-top: calc(var(--spacer) * 1.5);
    --margin-bottom: calc(var(--spacer) * 0.5);
} 
h5,.ns-title-5,h5 *,.ns-title-5 * {
    --type-font-size: 1.25rem;
    --type-font-weight: 500;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --margin-top: calc(var(--spacer) * 1.5);
    --margin-bottom: calc(var(--spacer) * 0.5);
} 
h6,.ns-title-6,h6 *,.ns-title-6 * {
    --type-font-size: 1rem;
    --type-font-weight: 700;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --margin-top: calc(var(--spacer) * 1.5);
    --margin-bottom: calc(var(--spacer) * 0.5);
} 
.ns-subtitle,.ns-subtitle-1,.ns-subtitle *,.ns-subtitle-1 * {
    --type-font-size: 1rem;
    --type-font-weight: 500;
    --type-font-color: var(--color-gray);
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --margin-bottom: calc(var(--spacer) * 0.5);
} 
.ns-subtitle-2,.ns-subtitle-2 * {
    --type-font-size: 0.8rem;
    --type-font-weight: 500;
    --type-font-color: var(--color-gray);
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --margin-bottom: calc(var(--spacer) * 0.5);
} 
p {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --margin-bottom: 1rem;
}
.center {
    --type-text-align: center;
}
.justify {
    --type-text-align: justify;
}
.left {
    --type-text-align: left;
}
.right {
    --type-text-align: right;
}
b {
    --type-font-style: inherit;
    --type-font-weight: 700;
}
i {
    --type-font-style: italic;
    --type-font-weight: inherit;
}
/* Listas */
ul, ol {
    --margin-left: calc(var(--spacer) * 2);
    --margin-top: var(--spacer);
    --margin-bottom: var(--spacer);
    --padding-left: calc(var(--spacer) * 0.5);
}
ul {
    --list-style: disc;
}
ol {
    --list-style: decimal;
}
ul ul, ol ol {
    --margin-top: 0;
    --margin-bottom: 0;
}
ul li, ol li {
    --margin-top: 0;
    --margin-right: 0;
    --margin-bottom: 0;
    --margin-left: 0;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}
dl {
    --margin-left: calc(var(--spacer) * 1.5);
    --margin-top: var(--spacer);
    --margin-bottom: var(--spacer);
}
dl.inline {
    --margin-top: 0;
    --margin-right: 0;
    --margin-bottom: calc((var(--spacer) * 1.5) * 0.5);
    --margin-left: 0;
}
dl dt {
    --margin-top: 0;
    --margin-right: 0;
    --margin-bottom: 0;
    --margin-left: 0;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}
dl dd {
    --margin-top: 0;
    --margin-right: 0;
    --margin-bottom: calc((var(--spacer) * 1.5) * 0.5);
    --margin-left: calc((var(--spacer) * 1.5) * 1);
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}
dl.inline dt,
dl.inline dd {
    --margin-top: 0;
    --margin-right: 0;
    --margin-bottom: 0;
    --margin-left: 0;
    --padding-top: 0.5rem;
    --padding-right: 0;
    --padding-bottom: 0.5rem;
    --padding-left: 0;
}
dl.inline dd {
    --padding-left: 1rem;
}
/* Referências e Mídia */
figure {
    --margin-top: var(--spacer);
    --margin-right: calc(var(--spacer) * 2);
    --margin-bottom: var(--spacer);
    --margin-left: calc(var(--spacer) * 2);
}
figure * {
    --margin-top: 0;
    --margin-right: 0;
    --margin-bottom: 0;
    --margin-left: 0;
}
figcaption {
    --type-font-size: 0.8rem;
    --type-font-style: italic;
    --type-font-weight: 400;
    --type-font-color: var(--color-gray);
}
picture, .ns-image,
video, .ns-video {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}
picture.square, .ns-image.square,
video.square, .ns-video.square {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 100%;
    --padding-left: 0;
}
picture.rectangle, .ns-image.rectangle,
video.rectangle, .ns-video.rectangle {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 75%;
    --padding-left: 0;
}
picture.wide, .ns-image.wide,
video.wide, .ns-video.wide {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 56.25%;
    --padding-left: 0;
}
picture.rectangle.portrait, .ns-image.rectangle.portrait,
video.rectangle.portrait, .ns-video.rectangle.portrait {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 133.33%;
    --padding-left: 0;
}
picture.wide.portrait, .ns-image.wide.portrait,
video.wide.portrait, .ns-video.wide.portrait {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 177.78%;
    --padding-left: 0;
}
picture img, .ns-image img, video > *, .ns-video > * {
    --padding-bottom: 0;
}
/* Botões */
.ns-btn-default,
.ns-btn-default * {
    --button-type-color: var(--color-default--font);
    --button-background: var(--color-default);
    --button-type-color--hover: var(--color-default--hoverfont);
    --button-background--hover: var(--color-default--hover);
}
.ns-btn-primary,
.ns-btn-primary * {
    --button-type-color: var(--color-primary--font);
    --button-background: var(--color-primary);
    --button-type-color--hover: var(--color-primary--hoverfont);
    --button-background--hover: var(--color-primary--hover);
}
.ns-btn-secondary,
.ns-btn-secondary * {
    --button-type-color: var(--color-secondary--font);
    --button-background: var(--color-secondary);
    --button-type-color--hover: var(--color-secondary--hoverfont);
    --button-background--hover: var(--color-secondary--hover);
}
.ns-btn-success,
.ns-btn-success * {
    --button-type-color: var(--color-success--font);
    --button-background: var(--color-success);
    --button-type-color--hover: var(--color-success--hoverfont);
    --button-background--hover: var(--color-success--hover);
}
.ns-btn-danger,
.ns-btn-danger * {
    --button-type-color: var(--color-danger--font);
    --button-background: var(--color-danger);
    --button-type-color--hover: var(--color-danger--hoverfont);
    --button-background--hover: var(--color-danger--hover);
}
.ns-btn-warning,
.ns-btn-warning * {
    --button-type-color: var(--color-warning--font);
    --button-background: var(--color-warning);
    --button-type-color--hover: var(--color-warning--hoverfont);
    --button-background--hover: var(--color-warning--hover);
}
.ns-btn-info,
.ns-btn-info * {
    --button-type-color: var(--color-info--font);
    --button-background: var(--color-info);
    --button-type-color--hover: var(--color-info--hoverfont);
    --button-background--hover: var(--color-info--hover);
}
.ns-btn-link,
.ns-btn-link * {
    --button-type-color: var(--color-link);
    --button-background: transparent;
    --button-type-color--hover: var(--color-link);
    --button-background--hover: transparent;
}
.ns-btn-light,
.ns-btn-light * {
    --button-type-color: var(--color-light--font);
    --button-background: var(--color-light);
    --button-type-color--hover: var(--color-light--hoverfont);
    --button-background--hover: var(--color-light--hover);
}
.ns-btn-dark,
.ns-btn-dark * {
    --button-type-color: var(--color-dark--font);
    --button-background: var(--color-dark);
    --button-type-color--hover: var(--color-dark--hoverfont);
    --button-background--hover: var(--color-dark--hover);
}
.ns-btn[disabled],.ns-btn[disabled] * {
    --button-background: var(--color-gray-lightest);
    --button-type-color: var(--color-gray-dark); 
    --button-type-color--hover: var(--color-gray-dark);
    --button-background--hover: var(--color-gray-lightest);
}
.ns-btn-default[disabled],
.ns-btn-default[disabled] * {
    --button-type-color: var(--color-default--font);
    --button-background: var(--color-default);
    --button-type-color--hover: var(--color-default--font);
    --button-background--hover: var(--color-default);
}
.ns-btn-primary[disabled],
.ns-btn-primary[disabled] * {
    --button-type-color: var(--color-primary--font);
    --button-background: var(--color-primary);
    --button-type-color--hover: var(--color-primary--font);
    --button-background--hover: var(--color-primary);
}
.ns-btn-secondary[disabled],
.ns-btn-secondary[disabled] * {
    --button-type-color: var(--color-secondary--font);
    --button-background: var(--color-secondary);
    --button-type-color--hover: var(--color-secondary--font);
    --button-background--hover: var(--color-secondary);
}
.ns-btn-success[disabled],
.ns-btn-success[disabled] * {
    --button-type-color: var(--color-success--font);
    --button-background: var(--color-success);
    --button-type-color--hover: var(--color-success--font);
    --button-background--hover: var(--color-success);
}
.ns-btn-danger[disabled],
.ns-btn-danger[disabled] * {
    --button-type-color: var(--color-danger--font);
    --button-background: var(--color-danger);
    --button-type-color--hover: var(--color-danger--font);
    --button-background--hover: var(--color-danger);
}
.ns-btn-warning[disabled],
.ns-btn-warning[disabled] * {
    --button-type-color: var(--color-warning--font);
    --button-background: var(--color-warning);
    --button-type-color--hover: var(--color-warning--font);
    --button-background--hover: var(--color-warning);
}
.ns-btn-info[disabled],
.ns-btn-info[disabled] * {
    --button-type-color: var(--color-info--font);
    --button-background: var(--color-info);
    --button-type-color--hover: var(--color-info--font);
    --button-background--hover: var(--color-info);
}
.ns-btn-light[disabled],
.ns-btn-light[disabled] * {
    --button-type-color: var(--color-light--font);
    --button-background: var(--color-light);
    --button-type-color--hover: var(--color-light--font);
    --button-background--hover: var(--color-light);
}
.ns-btn-dark[disabled],
.ns-btn-dark[disabled] * {
    --button-type-color: var(--color-dark--font);
    --button-background: var(--color-dark);
    --button-type-color--hover: var(--color-dark--font);
    --button-background--hover: var(--color-dark);
}
button,input[type="submit"],input[type="reset"],input[type="button"],
[class*=ns-btn] {
    --margin-top: 0;
    --margin-right: 0;
    --margin-bottom: 0;
    --margin-left: 0;
    --padding-top: 0;
    --padding-right: var(--button-padding);
    --padding-bottom: 0;
    --padding-left: var(--button-padding);
}
button,input[type="submit"],input[type="reset"],input[type="button"],
[class*=ns-btn], [class*=ns-btn] * {
    --button-padding: var(--spacer); 
    --button-letter-spacing: 2px;
    --button-line-height: 1.2;   
    --button-text-transform: uppercase; 
    --button-icon-spacer: calc(var(--spacer)/2);
    --animation: all 0.125s ease-out;
}
button > span, [class*=ns-btn] > span {
    --margin-top: 0;
    --margin-right: calc(var(--spacer)/5);
    --margin-bottom: 0;
    --margin-left: calc(var(--spacer)/5);
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}
.ns-btn-sm,.ns-btn-sm * {
    --button-height: var(--button-height-sm);
    --button-type-size: var(--button-type-size-sm);
    --button-icon-size: var(--button-icon-size-sm);
}
.ns-btn-lg,.ns-btn-lg * {
    --button-height: var(--button-height-lg);
    --button-type-size: var(--button-type-size-lg);
    --button-icon-size: var(--button-icon-size-lg);
}
.ns-btn-icon {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}
/* Formulários */
label, label * {
    --animation: color 0.125s ease-out, border 0.125s ease-out, font-size 0.125s ease-out, padding 0.125s ease-out, background 0.125s ease-out;
}
label > input[type="color"], label > input[type="date"], label > input[type="datetime-local"], 
label > input[type="email"], label > input[type="file"], label > input[type="image"], 
label > input[type="month"], label > input[type="number"], label > input[type="password"], 
label > input[type="range"], label > input[type="search"], label > input[type="tel"], 
label > input[type="text"], label > input[type="time"], label > input[type="url"],
label > input[type="week"], label > textarea, label > select {
    --padding-top: calc((var(--spacer)/2 * 1.5) + (var(--form-font-size-activity) * var(--type-line-height)));
    --padding-right: calc(var(--spacer)/2);
    --padding-bottom: calc( var(--form-height) - ((var(--spacer)/2 * 1.5) + (2 * (var(--form-font-size-activity) * var(--type-line-height)))));
    --padding-left: calc(var(--spacer)/2);
}

label > input[type="color"] + span:not([class]), label > input[type="date"] + span:not([class]),
label > input[type="datetime-local"] + span:not([class]), label > input[type="email"] + span:not([class]),
label > input[type="file"] + span:not([class]), label > input[type="image"] + span:not([class]),
label > input[type="month"] + span:not([class]), label > input[type="number"] + span:not([class]),
label > input[type="password"] + span:not([class]), label > input[type="range"] + span:not([class]),
label > input[type="search"] + span:not([class]), label > input[type="tel"] + span:not([class]),
label > input[type="text"] + span:not([class]), label > input[type="time"] + span:not([class]),
label > input[type="url"] + span:not([class]), label > input[type="week"] + span:not([class]),
label > textarea + span:not([class]), label > select + span:not([class]),
label > input[type="color"]:disabled + span:not([class]), label > input[type="date"]:disabled + span:not([class]),
label > input[type="datetime-local"]:disabled + span:not([class]), label > input[type="email"]:disabled + span:not([class]),
label > input[type="file"]:disabled + span:not([class]), label > input[type="image"]:disabled + span:not([class]),
label > input[type="month"]:disabled + span:not([class]), label > input[type="number"]:disabled + span:not([class]),
label > input[type="password"]:disabled + span:not([class]), label > input[type="range"]:disabled + span:not([class]),
label > input[type="search"]:disabled + span:not([class]), label > input[type="tel"]:disabled + span:not([class]),
label > input[type="text"]:disabled + span:not([class]), label > input[type="time"]:disabled + span:not([class]),
label > input[type="url"]:disabled + span:not([class]), label > input[type="week"]:disabled + span:not([class]),
label > textarea:disabled + span:not([class]), label > select:disabled + span:not([class]),
label > input[type="color"]:disabled:focus + span, label > input[type="date"]:disabled:focus + span,
label > input[type="datetime-local"]:disabled:focus + span, label > input[type="email"]:disabled:focus + span,
label > input[type="file"]:disabled:focus + span, label > input[type="image"]:disabled:focus + span,
label > input[type="month"]:disabled:focus + span, label > input[type="number"]:disabled:focus + span,
label > input[type="password"]:disabled:focus + span, label > input[type="range"]:disabled:focus + span,
label > input[type="search"]:disabled:focus + span, label > input[type="tel"]:disabled:focus + span,
label > input[type="text"]:disabled:focus + span, label > input[type="time"]:disabled:focus + span,
label > input[type="url"]:disabled:focus + span, label > input[type="week"]:disabled:focus + span,
label > input[type="color"]:disabled:active + span, label > input[type="date"]:disabled:active + span,
label > input[type="datetime-local"]:disabled:active + span, label > input[type="email"]:disabled:active + span,
label > input[type="file"]:disabled:active + span, label > input[type="image"]:disabled:active + span,
label > input[type="month"]:disabled:active + span, label > input[type="number"]:disabled:active + span,
label > input[type="password"]:disabled:active + span, label > input[type="range"]:disabled:active + span,
label > input[type="search"]:disabled:active + span, label > input[type="tel"]:disabled:active + span,
label > input[type="text"]:disabled:active + span, label > input[type="time"]:disabled:active + span,
label > input[type="url"]:disabled:active + span, label > input[type="week"]:disabled:active + span,
label > textarea:disabled:focus + span, label > textarea:disabled:active + span,
label > select:disabled:focus + span, label > select:disabled:active + span {
    --padding-top: calc((var(--form-height) - var(--form-font-size) * var(--type-line-height))/2);
    --padding-right: calc(var(--spacer)/2);
    --padding-bottom: calc((var(--form-height) - var(--form-font-size) * var(--type-line-height))/2);
    --padding-left: calc(var(--spacer)/2);

}
label > input[type="color"]:focus + span, label > input[type="date"]:focus + span, label > input[type="datetime-local"]:focus + span,
label > input[type="email"]:focus + span, label > input[type="file"]:focus + span, label > input[type="image"]:focus + span,
label > input[type="month"]:focus + span, label > input[type="number"]:focus + span, label > input[type="password"]:focus + span,
label > input[type="range"]:focus + span, label > input[type="search"]:focus + span, label > input[type="tel"]:focus + span,
label > input[type="text"]:focus + span, label > input[type="time"]:focus + span, label > input[type="url"]:focus + span,
label > input[type="week"]:focus + span, label > input[type="color"]:active + span, label > input[type="date"]:active + span,
label > input[type="datetime-local"]:active + span, label > input[type="email"]:active + span,
label > input[type="file"]:active + span, label > input[type="image"]:active + span, label > input[type="month"]:active + span,
label > input[type="number"]:active + span, label > input[type="password"]:active + span, label > input[type="range"]:active + span,
label > input[type="search"]:active + span, label > input[type="tel"]:active + span, label > input[type="text"]:active + span,
label > input[type="time"]:active + span, label > input[type="url"]:active + span, label > input[type="week"]:active + span,
label > input[type="color"]:not(:placeholder-shown) + span, label > input[type="date"]:not(:placeholder-shown) + span,
label > input[type="datetime-local"]:not(:placeholder-shown) + span, label > input[type="email"]:not(:placeholder-shown) + span,
label > input[type="file"]:not(:placeholder-shown) + span, label > input[type="image"]:not(:placeholder-shown) + span,
label > input[type="month"]:not(:placeholder-shown) + span, label > input[type="number"]:not(:placeholder-shown) + span,
label > input[type="password"]:not(:placeholder-shown) + span, label > input[type="range"]:not(:placeholder-shown) + span,
label > input[type="search"]:not(:placeholder-shown) + span, label > input[type="tel"]:not(:placeholder-shown) + span,
label > input[type="text"]:not(:placeholder-shown) + span, label > input[type="time"]:not(:placeholder-shown) + span,
label > input[type="url"]:not(:placeholder-shown) + span, label > input[type="week"]:not(:placeholder-shown) + span,
label > textarea:focus + span, label > textarea:active + span, label > textarea:not(:placeholder-shown) + span,
label > select:focus + span, label > select:active + span, label > select:not(:placeholder-shown) + span {
    --padding-top: calc(var(--spacer)/2);
}
label > input[type="color"]:not([placeholder=" "]) + span, label > input[type="date"]:not([placeholder=" "]) + span,
label > input[type="datetime-local"]:not([placeholder=" "]) + span, label > input[type="email"]:not([placeholder=" "]) + span,
label > input[type="file"]:not([placeholder=" "]) + span, label > input[type="image"]:not([placeholder=" "]) + span,
label > input[type="month"]:not([placeholder=" "]) + span, label > input[type="number"]:not([placeholder=" "]) + span,
label > input[type="password"]:not([placeholder=" "]) + span, label > input[type="range"]:not([placeholder=" "]) + span,
label > input[type="search"]:not([placeholder=" "]) + span, label > input[type="tel"]:not([placeholder=" "]) + span,
label > input[type="text"]:not([placeholder=" "]) + span, label > input[type="time"]:not([placeholder=" "]) + span,
label > input[type="url"]:not([placeholder=" "]) + span {
    --padding-top: calc(var(--spacer)/2);
}
label > input[type="color"] {
    --padding-top: calc(var(--spacer)/2 + (var(--form-font-size-activity) * var(--type-line-height)));
    --padding-bottom: calc(var(--spacer)/4);
}
label > input[type="range"] {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}
label > select {
    --padding-top: calc(var(--spacer)/2 + (var(--form-font-size-activity) * var(--type-line-height)));
    --padding-bottom: calc(var(--spacer)/4);
}
label > input[type="checkbox"] + span, label > input[type="radio"] + span {
    --padding-top: calc(var(--spacer)/4);
    --padding-right: calc(var(--spacer)/2);
    --padding-bottom: calc(var(--spacer)/4);
    --padding-left: 0;
}
label > .icon ~ input, label > .icon ~ input ~ span:not([class]) {
    --padding-left: calc(var(--spacer)/2 + var(--spacer)/4 + (var(--form-icon-size) * var(--type-line-height)));
}
.form-group {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: var(--spacer);
    --padding-left: 0;
}
.form-group.inline > label span {
    --padding-right: 0;
}
.ns-submit {
    --padding-top: var(--spacer);
    --padding-right: var(--spacer);
    --padding-bottom: var(--spacer);
    --padding-left: var(--spacer);
}
/* Cards */
.ns-card > header {
    --padding-top: var(--spacer);
    --padding-right: var(--spacer);
    --padding-bottom: 0;
    --padding-left: var(--spacer);
}
.ns-card > header * {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}
.ns-card > .body {
    --padding-top: var(--spacer);
    --padding-right: var(--spacer);
    --padding-bottom: var(--spacer);
    --padding-left: var(--spacer);
}
.ns-card > footer {
    --padding-top: 0;
    --padding-right: calc(var(--card-spacer)/2);
    --padding-bottom: var(--spacer);
    --padding-left: calc(var(--card-spacer)/2);
}
/* Tabelas */
table caption {
    --type-font-size: 0.8rem;
    --type-font-style: italic;
    --type-font-weight: 400;
    --type-font-color: var(--color-gray);
}
.ns-table table * {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}
.ns-table table thead th,
.ns-table table tbody td,
.ns-table table tfoot td {
    --padding-top: calc(var(--spacer)/2);
    --padding-right: calc(var(--spacer)/2);
    --padding-bottom: calc(var(--spacer)/2);
    --padding-left: calc(var(--spacer)/2);
}
.ns-table table thead th {
    --padding-top: calc(var(--spacer)/1.5);
    --padding-bottom: calc(var(--spacer)/1.5);
}
.ns-table table caption {
    --margin-top: calc(var(--spacer)/4);
    --margin-right: 0;
    --margin-bottom: 0;
    --margin-left: 0;
}
.ns-table table thead th:nth-of-type(1) label > input + span,
.ns-table table thead th:nth-of-type(1) label > input + span *, 
.ns-table table tbody td:nth-of-type(1) label > input + span,
.ns-table table tbody td:nth-of-type(1) label > input + span * {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}
/* Paginação */
.ns-pages ul {
    --margin-top: 0;
    --margin-right: 0;
    --margin-bottom: 0;
    --margin-left: 0;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --list-style: none;
}
.ns-pages ul li {
    --margin-top: 0;
    --margin-right: calc(var(--spacer)/4);
    --margin-bottom: calc(var(--spacer)/2);
    --margin-left: calc(var(--spacer)/4);
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --list-style: none;
}
.ns-pages ul li a  {
    --margin-top: 0;
    --margin-right: 0;
    --margin-bottom: 0;
    --margin-left: 0;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --animation: all 0.125s ease-out;
}
.ns-pages ul li a span { 
    --margin-top: 0;
    --margin-right: 0;
    --margin-bottom: 0;
    --margin-left: 0;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --type-font-size: 1rem;
    --type-font-weight: 400;
    --animation: all 0.125s ease-out;
}
/* Dialogs */
.ns-dialog > header {
    --padding-top: var(--spacer);
    --padding-right: var(--spacer);
    --padding-bottom: 0;
    --padding-left: var(--spacer);
}
.ns-dialog > header * {
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}
.ns-dialog > .body {
    --padding-top: var(--spacer);
    --padding-right: var(--spacer);
    --padding-bottom: var(--spacer);
    --padding-left: var(--spacer);
}
.ns-dialog > footer {
    --padding-top: 0;
    --padding-right: calc(var(--dialog-spacer)/2);
    --padding-bottom: var(--spacer);
    --padding-left: calc(var(--dialog-spacer)/2);
}
/* Menus */
.ns-menu ul,
.ns-menu ul span,
.ns-menu ul img {
    --margin-top: 0;
    --margin-bottom: 0;
    --margin-left: 0;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --list-style: none;
    --type-font-size: var(--menu-font-size);
    --type-font-weight: var(--menu-font-weight);
    --type-text-align: left;
    --vertical-align: middle;
}
.ns-menu ul > li > a > span.mdi,
.ns-menu ul > li > a > img {
    --margin-right: calc(var(--menu-spacer)/4);
}
.ns-menu ul {
    --padding-top: calc(var(--menu-spacer)/4);
    --padding-bottom: calc(var(--menu-spacer)/4);
}
.ns-menu ul li.title {
    --type-font-color: var(--menu-title-color);
    --type-font-weight: var(--menu-title-weight);
}
.ns-menu ul li.divider {
    --padding-top: calc(var(--menu-spacer)/4);
    --margin-bottom: calc(var(--menu-spacer)/4);
}
.ns-menu ul li.title,
.ns-menu ul li a {
    --padding-top: calc(var(--menu-spacer)/4);
    --padding-right: calc(var(--menu-spacer)/2);
    --padding-bottom: calc(var(--menu-spacer)/4);
    --padding-left: calc(var(--menu-spacer)/2);
}

/* Menus - Disciplinas */
.ns-menu-disciplinas ul,
.ns-menu-disciplinas ul span,
.ns-menu-disciplinas ul img {
    --margin-top: 0;
    --margin-bottom: 0;
    --margin-left: 0;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
    --list-style: none;
    --type-font-size: var(--menu-font-size);
    --type-font-weight: var(--menu-font-weight);
    --type-text-align: left;
    --vertical-align: middle;
}
.ns-menu-disciplinas ul > li > a > span.mdi,
.ns-menu-disciplinas ul > li > a > img {
    --margin-right: calc(var(--menu-spacer)/4);
}
.ns-menu-disciplinas ul {
    --padding-top: calc(var(--menu-spacer)/4);
    --padding-bottom: calc(var(--menu-spacer)/4);
}
.ns-menu-disciplinas ul li.title {
    --type-font-color: var(--menu-title-color);
    --type-font-weight: var(--menu-title-weight);
}
.ns-menu-disciplinas ul li.divider {
    --padding-top: calc(var(--menu-spacer)/4);
    --margin-bottom: calc(var(--menu-spacer)/4);
}
.ns-menu-disciplinas ul li.title,
.ns-menu-disciplinas ul li a {
    --padding-top: calc(var(--menu-spacer)/4);
    --padding-right: calc(var(--menu-spacer)/2);
    --padding-bottom: calc(var(--menu-spacer)/4);
    --padding-left: calc(var(--menu-spacer)/2);
}
/* carousel */
.ns-carousel.nav .slick-arrow {
    --margin-top: 0;
    --margin-right: 0;
    --margin-bottom: 0;
    --margin-left: 0;
    --padding-top: 0;
    --padding-right: 0;
    --padding-bottom: 0;
    --padding-left: 0;
}