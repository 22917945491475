/* Header (Principal, não logado) */
body {
    padding-top: var(--menu-height);
}
/* TELA DE LOGIN */
#acesso_login,
#index_index {
    width: 100%;
    min-height: 100vh;
    display: grid;
    padding-top: 0;
    grid-template-columns: 59.58% 1fr;
    grid-template-rows: 130px 1fr;
    grid-template-areas: "menu menu" "carousel login";
}
#acesso_login .ips-mainmenu--open,
#index_index .ips-mainmenu--open {
    grid-area: menu;
    width: 100%;
    height: var(--menu-height);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 calc(2 * var(--spacer));
    background: var(--color-secondary);
}
#acesso_login .ips-mainmenu--open h1,
#acesso_login .ips-mainmenu--open h1 img, 
#index_index .ips-mainmenu--open h1,
#index_index .ips-mainmenu--open h1 img {
    margin: 0;
}
#acesso_login .left--container, 
#index_index .left--container {
    grid-area: carousel;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    justify-self: stretch;
    align-self: stretch;
    padding: 65px 0 0;
    background: var(--color-secondary-light);
}
#acesso_login .left--container .carousel--container , 
#index_index .left--container .carousel--container {
    width: 80%;
    max-width: 702px;
}
#acesso_login .right--container,
#index_index .right--container {
    grid-area: login;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    justify-self: stretch;
    align-self: stretch;
    padding: 65px 0 0;
    background: var(--color-gray-lightest-v2);
}
#acesso_login  .right--container .login-box .ns-card, 
#index_index .right--container .login-box .ns-card {
    width: 420px;
    border-radius: var(--base-radius);
    background: var(--color-primary-dark);
}
#acesso_login .right--container .login-box .ns-card > .body, 
#index_index .right--container .login-box .ns-card > .body {
    padding: 60px;
}
#acesso_login .right--container .login-box .ns-card > .body > .ns-form *, 
#index_index .right--container .login-box .ns-card > .body > .ns-form * {
    --form-color: #fff;
    --type-font-color: #fff;
    --color-link: #fff;
    color: var(--form-color);
}
#acesso_login .right--container .login-box .ns-card .logo,
#index_index .right--container .login-box .ns-card .logo {
    padding: 0 0 60px;
    text-align: center;
}
@media(max-width: 1220px) {
    #acesso_login ,
    #index_index {
        grid-template-columns: 50% 1fr;
    }
    #acesso_login .right--container .login-box .ns-card, 
    #index_index .right--container .login-box .ns-card {
        width: 400px;
    }
}
@media(max-width: 900px) {
    #acesso_login,
    #index_index {
        grid-template-columns: 100%;
        grid-template-rows: auto;
        grid-template-areas: "menu" "carousel" "login";
    }
    #acesso_login .left--container, 
    #acesso_login .right--container, 
    #index_index .left--container,
    #index_index .right--container {
        padding: 40px 0;
    }
}

/* MEU ESPAÇO */
#meu-espaco_index .ips-body-container {
    margin-top: calc(var(--spacer)*2);
    margin-bottom: calc(var(--spacer)*2);
    display: grid;
    grid-template-columns: 66% 1fr;
    grid-template-areas: "disciplinas agenda" "avaliacoes avaliacoes";
    gap: calc(var(--spacer) * 2);
}
#meu-espaco_index .ips-body-container #disciplinas-container {
    grid-area: disciplinas;
}
#meu-espaco_index .ips-body-container #agenda-container {
    grid-area: agenda;
}
#meu-espaco_index .ips-body-container #avaliacoes-container {
    grid-area: avaliacoes;
}
@media (max-width: 769px) {
    #meu-espaco_index .ips-body-container {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "disciplinas disciplinas" "agenda avaliacoes";
    }
}
@media (max-width: 576px) {
    #meu-espaco_index .ips-body-container {
        grid-template-columns: 100%;
        grid-template-areas: "disciplinas" "agenda" "avaliacoes";
    }
}
/* LISTAGEM DE DISCIPLINAS */
#meu-espaco_disciplinas .ips-body-container {
    margin-top: calc(var(--spacer)*2);
    margin-bottom: calc(var(--spacer)*2);
    display: grid;
    grid-template-columns: minmax(20%,269px) 1fr;
    grid-template-areas: "filtros disciplinas" "filtros historicas";
    gap: calc(var(--spacer) * 2);
}
#meu-espaco_disciplinas .ips-body-container #disciplinas-container {
    grid-area: disciplinas;
}
#meu-espaco_disciplinas .ips-body-container #historicas-container {
    grid-area: historicas;
}
#meu-espaco_disciplinas .ips-body-container #filtros-container {
    grid-area: filtros;
}
#meu-espaco_disciplinas .ips-body-container #filtros-container .ips-filter {
    border-radius: var(--base-radius);
    background: var(--color-gray-lightest-v2);
    border: 1px solid var(--color-gray-lighter);
}
#meu-espaco_disciplinas .ips-body-container #filtros-container .ips-filter header {
    width: 100%;
    height: var(--box-header);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacer);
    border-top-left-radius: var(--base-radius);
    border-top-right-radius: var(--base-radius);
    background: var(--color-gray-lighter);
}
#meu-espaco_disciplinas .ips-body-container #filtros-container .ips-filter header .title {
    margin: 0;
    padding: 0 var(--spacer) 0 0;
    font-size: 1.375rem;
    font-weight: 400;
    color: var(--color-darkest);
}
#meu-espaco_disciplinas .ips-body-container #filtros-container .ips-filter .filters {
    padding: calc(var(--spacer) * 1.4) var(--spacer);
}
@media (max-width: 769px) {
    #meu-espaco_disciplinas .ips-body-container {
        grid-template-columns: 180px 1fr;
    }
}

/* ITEM DE CRONOGRAMA */
#comunidade_index .ips-body-container,
#comunidade_comunicados .ips-body-container {
    margin-top: calc(var(--spacer)*2);
    margin-bottom: calc(var(--spacer)*2);
    display: grid;
    grid-template-columns: minmax(20%,269px) 1fr;
    grid-template-areas: "aside content";
    gap: calc(var(--spacer) * 2);
}
#comunidade_index .ips-body-container #aside-container,
#comunidade_comunicados .ips-body-container #aside-container {
    grid-area: aside;
}
#comunidade_index .ips-body-container #content-container,
#comunidade_comunicados .ips-body-container #content-container {
    grid-area: content;
}
#comunidade_index .ips-body-container #content-container h2,
#comunidade_comunicados .ips-body-container #content-container h2 {
    margin-top: 0;
}
@media (max-width: 769px) {
    #comunidade_index .ips-body-container,
    #comunidade_comunicados .ips-body-container {
        grid-template-columns: 100%;
        grid-template-areas: "content" "aside" ;
    }
}

/* ITEM DE CRONOGRAMA */
#comunidade_item .ips-body-container,
#comunidade_comunicados .ips-body-container {
    margin-top: calc(var(--spacer)*2);
    margin-bottom: calc(var(--spacer)*2);
    display: grid;
    grid-template-columns: minmax(20%,269px) 1fr;
    grid-template-areas: "aside content";
    gap: calc(var(--spacer) * 2);
}
#comunidade_item .ips-body-container #aside-container,
#comunidade_comunicados .ips-body-container #aside-container {
    grid-area: aside;
}
#comunidade_item .ips-body-container #content-container,
#comunidade_comunicados .ips-body-container #content-container {
    grid-area: content;
}
#comunidade_item .ips-body-container #content-container h2,
#comunidade_comunicados .ips-body-container #content-container h2 {
    margin-top: 0;
}
@media (max-width: 769px) {
    #comunidade_item .ips-body-container,
    #comunidade_comunicados .ips-body-container {
        grid-template-columns: 100%;
        grid-template-areas: "content" "aside" ;
    }
}
/* TRABALHOS */
#meu-espaco_trabalhos .ips-body-container {
    margin-top: calc(var(--spacer)*2);
    margin-bottom: calc(var(--spacer)*2);
    display: grid;
    grid-template-columns: 100%;
    gap: var(--spacer);
}
/* AVALIAÇÕES */
#meu-espaco_avaliacoes .ips-body-container {
    margin-top: calc(var(--spacer)*2);
    margin-bottom: calc(var(--spacer)*2);
    display: grid;
    grid-template-columns: 100%;
    gap: var(--spacer);
}
/* COMUNICADOS */
#community_comunicados .ips-body-container {
    margin-top: calc(var(--spacer)*2);
    margin-bottom: calc(var(--spacer)*2);
    display: grid;
    grid-template-columns: minmax(20%,269px) 1fr;
    grid-template-areas: "aside content";
    gap: calc(var(--spacer) * 2);
}
@media (max-width: 769px) {
    #community_comunicados .ips-body-container {
        grid-template-columns: 100%;
        grid-template-areas: "aside" "content";
    }
}
/* AGENDA */
#meu-espaco_agenda .ips-body-container {
    margin-top: calc(var(--spacer)*2);
    margin-bottom: calc(var(--spacer)*2);
    display: grid;
    grid-template-columns: minmax(34%,468px) 1fr;
    grid-template-areas: "agenda content";
    gap: calc(var(--spacer) * 2);
}
#meu-espaco_agenda #agenda-container {
    grid-area: agenda;
}
#meu-espaco_agenda #agenda-container .agenda-box {
    border-radius: var(--base-radius);
    background: var(--color-gray-lightest-v2);
    border: 1px solid var(--color-gray-lighter);
}
#meu-espaco_agenda #agenda-container .agenda-box header {
    width: 100%;
    height: var(--box-header);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacer);
    border-top-left-radius: var(--base-radius);
    border-top-right-radius: var(--base-radius);
    background: var(--color-primary);
}
#meu-espaco_agenda #agenda-container .agenda-box header h2 {
    margin: 0;
    padding: 0 var(--spacer) 0 0;
    font-size: 1.375rem;
    font-weight: 400;
    color: var(--color-lightest);
}
#meu-espaco_agenda #agenda-container .agenda-box .body {
    padding: var(--spacer);
}
#meu-espaco_agenda #eventos-container {
    grid-area: content;
}
#meu-espaco_agenda #eventos-container > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
#meu-espaco_agenda #eventos-container  > ul > li,
#meu-espaco_agenda #eventos-container  > ul > li .title {
    margin: 0;
    padding: 0;
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--color-darkest);
}
#meu-espaco_agenda #eventos-container  > ul > li ul {
    margin: 0.5rem 0 1rem 0.5rem;
    color: var(--color-primary);
}
#meu-espaco_agenda #eventos-container  > ul > li ul li a {
    font-size: 0.875rem;
    text-decoration: none;
    color: var(--color-gray);
}
@media (max-width: 769px) {
    #meu-espaco_agenda .ips-body-container {
        grid-template-columns: 100%;
        grid-template-areas: "agenda" "content";
    }
}
/* MEUS DADOS */
#meu-espaco_meus-dados .ips-body-container {
    margin-top: calc(var(--spacer)*2);
    margin-bottom: calc(var(--spacer)*2);
    display: grid;
    grid-template-columns: minmax(20%,165px) 1fr;
    grid-template-areas: "side content";
    gap: calc(var(--spacer) * 2);
}
#meu-espaco_meus-dados #side-container {
    grid-area: side;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
#meu-espaco_meus-dados #side-container .avatar {
    width: 165px;
    height: 165px;
    overflow: hidden;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    margin: 0 0 var(--spacer);
}
#meu-espaco_meus-dados #content-container h2 {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 400;
    color: var(--color-darkest);
}
#meu-espaco_meus-dados #content-container h2:nth-of-type(n+2) {
    margin-top: var(--spacer);
} 
@media (max-width: 769px) {
    #meu-espaco_meus-dados .ips-body-container {
        grid-template-columns: 100%;
        grid-template-areas: "side" "content";
    }
}

/* ITEM DE CRONOGRAMA */
#meu-espaco_disco-virtual .ips-body-container {
    margin-top: calc(var(--spacer)*2);
    margin-bottom: calc(var(--spacer)*2);
    display: grid;
    grid-template-columns: minmax(20%,269px) 1fr;
    grid-template-areas: "aside content";
    gap: calc(var(--spacer) * 2);
}
#meu-espaco_disco-virtual .ips-body-container #aside-container {
    grid-area: aside;
}
#meu-espaco_disco-virtual .ips-body-container #content-container {
    grid-area: content;
}
#meu-espaco_disco-virtual .ips-body-container #content-container h2 {
    margin-top: 0;
}
@media (max-width: 769px) {
    #meu-espaco_disco-virtual .ips-body-container {
        grid-template-columns: 100%;
        grid-template-areas: "content" "aside" ;
    }
}
#user .avatar img
{
    width: 40px;
}
#disciplinas .ns-menu.level-2 ul
{
    max-height: 80vh;
}
#onetrust-consent-sdk button::before
{
    display: none;
}

#avaliacoes-container #list ul
{
    padding-left: 20px;
}

#avaliacoes-container  #list ul li ul
{
    list-style-type: square;
}

#toast-container {
    position: fixed;
    bottom: 0px;
    width: 100vw;
    z-index: 1500;
}
#toast-container div
{
    width: 100%;
    padding: 0px;
    margin: 0px;
}
#toast-container div.toast-box
{
    padding: 20px;
    border-radius: 6px;
}

#toast-container div.toast .error
{    
    background-color: red;
    color: white;
}


#toast-container div.toast .success
{    
    background-color: green;
    color: white;
}
#toast-container div.toast .error span,
#toast-container div.toast .success span
{
    color: white;
}


#toast-container div.toast .alert
{    
    background-color: yellow;
    color: black;
}
#toast-container div.toast .alert span
{
    color: black;
}
#toast-container div.toast .error .content span
{
    color: white;
}

#toast-container div.toast .alert .content span
{
    color: black;
}

#toast-container div.toast a.close
{
    float: right;
    margin-top: -20px;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
}
#toast-container div.toast a.close span
{
    color: white;
    
    font-weight: 500;
    
}

#acesso_login .right--container .login-box .ns-card > .body > .ns-form select option, 
#index_index .right--container .login-box .ns-card > .body > .ns-form  select option
{
    color: #000;
}