/* Listas */
ul li,
ol li {
    list-style: inherit;
}
dl.inline {
    display: grid;
    grid-template-columns: auto 1fr;
}
dl dt {
    font-weight: 700;
}