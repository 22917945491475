.ips-communitymessages {
    display: grid;
    grid-columns: 1fr 1fr;
    gap: var(--spacer);
}
.ips-communitymessages h2 {
    grid-column: span 2;
    font-size: 1.375;
    font-weight: 400;
    color: var(--color-darkest);
    margin: 0; 
}
.ips-communitymessages--item {
    padding: var(--spacer); 
    border-radius: var(--base-radius);
    border: 1px solid var(--color-gray-lighter);
    background: var(--color-gray-lightest);
}
.ips-communitymessages--item .author {
    display: block;
    margin: 0 0 calc(var(--spacer)/2);
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--color-gray-darker);
}
.ips-communitymessages--item .content {
    display: block;
    font-size: 0.875rem;
    font-style: italic;
    color: var(--color-gray-darker);
}