.ips-vdisk {

}
.ips-vdisk .top {
    display: flex;
    justify-content: space-between;
    align-items: center;    
}
.ips-vdisk .top .path {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center; 
}
.ips-vdisk .top .path li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.ips-vdisk .top .path li:before {
    width: 0.5rem;
    height: 0.5rem;
    display: block;
    margin: 0 calc(var(--spacer)/2);
    content:"";
    border-right: 1px solid var(--color-gray);
    border-bottom: 1px solid var(--color-gray);
    transform: rotate(-45deg);
}
.ips-vdisk .top .path li:first-of-type:before {
    display: none;
}
.ips-vdisk .top .path li a {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--color-darkest);
    text-decoration: none;
}
.ips-vdisk .list {
    padding: var(--spacer) 0;
}
.ips-vdisk .list ul {
    --grid-cols: 5;
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(var(--grid-cols),1fr);
    gap: var(--spacer);
}
.ips-vdisk--item {
    padding: calc(var(--spacer)/2); 
    border-radius: var(--base-radius);
    border: 1px solid var(--color-gray-lighter);
}
.ips-vdisk--item a {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    grid-template-areas: "title" "icon";
    text-decoration: none;
    gap: var(--spacer);
}
.ips-vdisk--item .title {
    grid-area: title;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--color-darkest);
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;    
    white-space: nowrap;
}
.ips-vdisk--item .type {
    grid-area: icon;
    display: flex;
    padding: 0.75rem 0 0;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--color-primary)
}
.ips-vdisk--item img {
    grid-area: icon;
    justify-self: center;
    align-self: center;
}
.ips-vdisk .list[data-view="list"] ul {
    display: block;
}
.ips-vdisk .list[data-view="list"] ul li a {
    grid-template-columns: calc(var(--spacer)*1.5) 1fr;
    grid-template-rows: auto;
    grid-template-areas: "icon title";
}
.ips-vdisk .list[data-view="list"] ul li a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.ips-vdisk .list[data-view="list"] .ips-vdisk--item {
    padding: calc(var(--spacer)/2);
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid var(--color-gray-lighter);
}
.ips-vdisk .list[data-view="list"] .ips-vdisk--item .type {
    font-size: 0.6rem;
}
.ips-vdisk--item .title {
    align-self: center;
}
@media (max-width: 1200px) {
    .ips-vdisk .list ul {
        --grid-cols: 4;
    }
}
@media (max-width: 992px) {
    .ips-vdisk .list ul {
        --grid-cols: 3;
    }
}
@media (max-width: 576px) {
    .ips-vdisk .list ul {
        --grid-cols: 2;
    }
}
@media (max-width: 320px) {
    .ips-vdisk .list ul {
        --grid-cols: 1;
    }
}