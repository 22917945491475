.ns-form {
    display: grid;
    grid-template-columns: repeat(var(--grid-columns),1fr);
    gap: var(--grid-spacer);
}
label {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "input";
    gap: 0;
    position: relative;
}
label[data-tip],
label[data-error] {
    grid-template-rows: auto auto;
    grid-template-areas: "input" "extrainfo";
}
label > input::-webkit-input-placeholder {
    color: var(--form-placeholder-color);
}
label > input::-moz-placeholder {
    color: var(--form-placeholder-color);
}
label > input:-moz-placeholder {
    color: var(--form-placeholder-color);
}
label > input:-ms-input-placeholder {
    color: var(--form-placeholder-color);
}
label > input::placeholder {
    color: var(--form-placeholder-color);
}
label > input[type="color"],
label > input[type="date"],
label > input[type="datetime-local"],
label > input[type="email"],
label > input[type="file"],
label > input[type="image"],
label > input[type="month"],
label > input[type="number"],
label > input[type="password"],
label > input[type="range"],
label > input[type="search"],
label > input[type="tel"],
label > input[type="text"],
label > input[type="time"],
label > input[type="url"],
label > input[type="week"],
label > textarea,
label > select {
    grid-area: input;
    width: 100%;
    height: var(--form-height);
    position: relative;
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    border: 0;
    font-size: var(--form-font-size);
    color: var(--form-input-color);
    background: transparent;
    z-index: 3;
    transition: var(--animation);
}
label > input[type="image"],
label > textarea {
    height: 100%;
    min-height: var(--form-height);
}
label > input[type="color"]:focus-visible,
label > input[type="date"]:focus-visible,
label > input[type="datetime-local"]:focus-visible,
label > input[type="email"]:focus-visible,
label > input[type="file"]:focus-visible,
label > input[type="image"]:focus-visible,
label > input[type="month"]:focus-visible,
label > input[type="number"]:focus-visible,
label > input[type="password"]:focus-visible,
label > input[type="range"]:focus-visible,
label > input[type="search"]:focus-visible,
label > input[type="tel"]:focus-visible,
label > input[type="text"]:focus-visible,
label > input[type="time"]:focus-visible,
label > input[type="url"]:focus-visible,
label > input[type="week"]:focus-visible,
label > input:focus-visible,
label > textarea:focus-visible,
label > select:focus-visible {
    outline: none;
}
label > input[type="color"] + span:not([class]),
label > input[type="date"] + span:not([class]),
label > input[type="datetime-local"] + span:not([class]),
label > input[type="email"] + span:not([class]),
label > input[type="file"] + span:not([class]),
label > input[type="image"] + span:not([class]),
label > input[type="month"] + span:not([class]),
label > input[type="number"] + span:not([class]),
label > input[type="password"] + span:not([class]),
label > input[type="range"] + span:not([class]),
label > input[type="search"] + span:not([class]),
label > input[type="tel"] + span:not([class]),
label > input[type="text"] + span:not([class]),
label > input[type="time"] + span:not([class]),
label > input[type="url"] + span:not([class]),
label > input[type="week"] + span:not([class]),
label > textarea + span:not([class]),
label > select + span:not([class]),
label > input[type="color"]:disabled + span:not([class]),
label > input[type="date"]:disabled + span:not([class]),
label > input[type="datetime-local"]:disabled + span:not([class]),
label > input[type="email"]:disabled + span:not([class]),
label > input[type="file"]:disabled + span:not([class]),
label > input[type="image"]:disabled + span:not([class]),
label > input[type="month"]:disabled + span:not([class]),
label > input[type="number"]:disabled + span:not([class]),
label > input[type="password"]:disabled + span:not([class]),
label > input[type="range"]:disabled + span:not([class]),
label > input[type="search"]:disabled + span:not([class]),
label > input[type="tel"]:disabled + span:not([class]),
label > input[type="text"]:disabled + span:not([class]),
label > input[type="time"]:disabled + span:not([class]),
label > input[type="url"]:disabled + span:not([class]),
label > input[type="week"]:disabled + span:not([class]),
label > textarea:disabled + span:not([class]),
label > select:disabled + span:not([class]) {
    grid-area: input;
    width: 100%;
    height: var(--form-height);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    border-radius: var(--form-border-radius) var(--form-border-radius) 0 0;
    border-bottom: var(--form-border-size) solid var(--form-color);
    font-size: var(--form-font-size);
    color: var(--form-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: var(--form-background);
    z-index: 1;
    transition: var(--animation);
}
label > input[type="color"]:disabled:hover,
label > input[type="date"]:disabled:hover,
label > input[type="datetime-local"]:disabled:hover,
label > input[type="email"]:disabled:hover,
label > input[type="file"]:disabled:hover,
label > input[type="image"]:disabled:hover,
label > input[type="month"]:disabled:hover,
label > input[type="number"]:disabled:hover,
label > input[type="password"]:disabled:hover,
label > input[type="range"]:disabled:hover,
label > input[type="search"]:disabled:hover,
label > input[type="tel"]:disabled:hover,
label > input[type="text"]:disabled:hover,
label > input[type="time"]:disabled:hover,
label > input[type="url"]:disabled:hover,
label > input[type="week"]:disabled:hover,
label > textarea:disabled:hover,
label > select:disabled:hover {
    cursor: not-allowed;
}
label > input[type="color"]:disabled + span:not([class]),
label > input[type="date"]:disabled + span:not([class]),
label > input[type="datetime-local"]:disabled + span:not([class]),
label > input[type="email"]:disabled + span:not([class]),
label > input[type="file"]:disabled + span:not([class]),
label > input[type="image"]:disabled + span:not([class]),
label > input[type="month"]:disabled + span:not([class]),
label > input[type="number"]:disabled + span:not([class]),
label > input[type="password"]:disabled + span:not([class]),
label > input[type="range"]:disabled + span:not([class]),
label > input[type="search"]:disabled + span:not([class]),
label > input[type="tel"]:disabled + span:not([class]),
label > input[type="text"]:disabled + span:not([class]),
label > input[type="time"]:disabled + span:not([class]),
label > input[type="url"]:disabled + span:not([class]),
label > input[type="week"]:disabled + span:not([class]),
label > textarea:disabled + span:not([class]),
label > select:disabled + span:not([class]) {
    transition: none;
    opacity: 0.6;
    font-size: var(--form-font-size-activity);
    padding-top: 10px;
}
.ns-form.outline label > input[type="color"] + span:not([class]),
.ns-form.outline label > input[type="date"] + span:not([class]),
.ns-form.outline label > input[type="datetime-local"] + span:not([class]),
.ns-form.outline label > input[type="email"] + span:not([class]),
.ns-form.outline label > input[type="file"] + span:not([class]),
.ns-form.outline label > input[type="image"] + span:not([class]),
.ns-form.outline label > input[type="month"] + span:not([class]),
.ns-form.outline label > input[type="number"] + span:not([class]),
.ns-form.outline label > input[type="password"] + span:not([class]),
.ns-form.outline label > input[type="range"] + span:not([class]),
.ns-form.outline label > input[type="search"] + span:not([class]),
.ns-form.outline label > input[type="tel"] + span:not([class]),
.ns-form.outline label > input[type="text"] + span:not([class]),
.ns-form.outline label > input[type="time"] + span:not([class]),
.ns-form.outline label > input[type="url"] + span:not([class]),
.ns-form.outline label > input[type="week"] + span:not([class]),
.ns-form.outline label > textarea + span:not([class]),
.ns-form.outline label > select + span:not([class]),
label.outline > input[type="color"] + span:not([class]),
label.outline > input[type="date"] + span:not([class]),
label.outline > input[type="datetime-local"] + span:not([class]),
label.outline > input[type="email"] + span:not([class]),
label.outline > input[type="file"] + span:not([class]),
label.outline > input[type="image"] + span:not([class]),
label.outline > input[type="month"] + span:not([class]),
label.outline > input[type="number"] + span:not([class]),
label.outline > input[type="password"] + span:not([class]),
label.outline > input[type="range"] + span:not([class]),
label.outline > input[type="search"] + span:not([class]),
label.outline > input[type="tel"] + span:not([class]),
label.outline > input[type="text"] + span:not([class]),
label.outline > input[type="time"] + span:not([class]),
label.outline > input[type="url"] + span:not([class]),
label.outline > input[type="week"] + span:not([class]),
label.outline > textarea + span:not([class]),
label.outline > select + span:not([class]) {
    border-radius: var(--form-border-radius);
    border: var(--form-border-size) solid var(--form-color);
    background: transparent;
}
label > input[type="image"] + span:not([class]),
label > textarea + span:not([class]) {
    height: 100%;
}
label > input[type="color"]:focus + span,
label > input[type="date"]:focus + span,
label > input[type="datetime-local"]:focus + span,
label > input[type="email"]:focus + span,
label > input[type="file"]:focus + span,
label > input[type="image"]:focus + span,
label > input[type="month"]:focus + span,
label > input[type="number"]:focus + span,
label > input[type="password"]:focus + span,
label > input[type="range"]:focus + span,
label > input[type="search"]:focus + span,
label > input[type="tel"]:focus + span,
label > input[type="text"]:focus + span,
label > input[type="time"]:focus + span,
label > input[type="url"]:focus + span,
label > input[type="week"]:focus + span,
label > input[type="color"]:active + span,
label > input[type="date"]:active + span,
label > input[type="datetime-local"]:active + span,
label > input[type="email"]:active + span,
label > input[type="file"]:active + span,
label > input[type="image"]:active + span,
label > input[type="month"]:active + span,
label > input[type="number"]:active + span,
label > input[type="password"]:active + span,
label > input[type="range"]:active + span,
label > input[type="search"]:active + span,
label > input[type="tel"]:active + span,
label > input[type="text"]:active + span,
label > input[type="time"]:active + span,
label > input[type="url"]:active + span,
label > input[type="week"]:active + span,
label > input[type="color"]:not(:placeholder-shown) + span,
label > input[type="date"]:not(:placeholder-shown) + span,
label > input[type="datetime-local"]:not(:placeholder-shown) + span,
label > input[type="email"]:not(:placeholder-shown) + span,
label > input[type="file"]:not(:placeholder-shown) + span,
label > input[type="image"]:not(:placeholder-shown) + span,
label > input[type="month"]:not(:placeholder-shown) + span,
label > input[type="number"]:not(:placeholder-shown) + span,
label > input[type="password"]:not(:placeholder-shown) + span,
label > input[type="range"]:not(:placeholder-shown) + span,
label > input[type="search"]:not(:placeholder-shown) + span,
label > input[type="tel"]:not(:placeholder-shown) + span,
label > input[type="text"]:not(:placeholder-shown) + span,
label > input[type="time"]:not(:placeholder-shown) + span,
label > input[type="url"]:not(:placeholder-shown) + span,
label > input[type="week"]:not(:placeholder-shown) + span,
label > textarea:focus + span,
label > textarea:active + span,
label > textarea:not(:placeholder-shown) + span,
label > select:focus + span,
label > select:active + span,
label > select:not(:placeholder-shown) + span {
    align-items: flex-start;
    font-size: var(--form-font-size-activity);
}
label > input[type="color"]:not([placeholder=" "]) + span,
label > input[type="date"]:not([placeholder=" "]) + span,
label > input[type="datetime-local"]:not([placeholder=" "]) + span,
label > input[type="email"]:not([placeholder=" "]) + span,
label > input[type="file"]:not([placeholder=" "]) + span,
label > input[type="image"]:not([placeholder=" "]) + span,
label > input[type="month"]:not([placeholder=" "]) + span,
label > input[type="number"]:not([placeholder=" "]) + span,
label > input[type="password"]:not([placeholder=" "]) + span,
label > input[type="range"]:not([placeholder=" "]) + span,
label > input[type="search"]:not([placeholder=" "]) + span,
label > input[type="tel"]:not([placeholder=" "]) + span,
label > input[type="text"]:not([placeholder=" "]) + span,
label > input[type="time"]:not([placeholder=" "]) + span,
label > input[type="url"]:not([placeholder=" "]) + span {
    align-items: flex-start;
    font-size: var(--form-font-size-activity);
}
label > input[type="color"]:focus + span,
label > input[type="date"]:focus + span,
label > input[type="datetime-local"]:focus + span,
label > input[type="email"]:focus + span,
label > input[type="file"]:focus + span,
label > input[type="image"]:focus + span,
label > input[type="month"]:focus + span,
label > input[type="number"]:focus + span,
label > input[type="password"]:focus + span,
label > input[type="range"]:focus + span,
label > input[type="search"]:focus + span,
label > input[type="tel"]:focus + span,
label > input[type="text"]:focus + span,
label > input[type="time"]:focus + span,
label > input[type="url"]:focus + span,
label > input[type="week"]:focus + span,
label > input[type="color"]:active + span,
label > input[type="date"]:active + span,
label > input[type="datetime-local"]:active + span,
label > input[type="email"]:active + span,
label > input[type="file"]:active + span,
label > input[type="image"]:active + span,
label > input[type="month"]:active + span,
label > input[type="number"]:active + span,
label > input[type="password"]:active + span,
label > input[type="range"]:active + span,
label > input[type="search"]:active + span,
label > input[type="tel"]:active + span,
label > input[type="text"]:active + span,
label > input[type="time"]:active + span,
label > input[type="url"]:active + span,
label > input[type="week"]:active + span,
label > textarea:focus + span,
label > textarea:active + span,
label > select:focus + span,
label > select:active + span {
    color: var(--form-color-activity);
    border-bottom: var(--form-border-size) solid var(--form-color-activity);
    background: var(--form-background-activity);
}
.ns-form.outline label > input[type="color"]:focus + span,
.ns-form.outline label > input[type="date"]:focus + span,
.ns-form.outline label > input[type="datetime-local"]:focus + span,
.ns-form.outline label > input[type="email"]:focus + span,
.ns-form.outline label > input[type="file"]:focus + span,
.ns-form.outline label > input[type="image"]:focus + span,
.ns-form.outline label > input[type="month"]:focus + span,
.ns-form.outline label > input[type="number"]:focus + span,
.ns-form.outline label > input[type="password"]:focus + span,
.ns-form.outline label > input[type="range"]:focus + span,
.ns-form.outline label > input[type="search"]:focus + span,
.ns-form.outline label > input[type="tel"]:focus + span,
.ns-form.outline label > input[type="text"]:focus + span,
.ns-form.outline label > input[type="time"]:focus + span,
.ns-form.outline label > input[type="url"]:focus + span,
.ns-form.outline label > input[type="week"]:focus + span,
.ns-form.outline label > input[type="color"]:active + span,
.ns-form.outline label > input[type="date"]:active + span,
.ns-form.outline label > input[type="datetime-local"]:active + span,
.ns-form.outline label > input[type="email"]:active + span,
.ns-form.outline label > input[type="file"]:active + span,
.ns-form.outline label > input[type="image"]:active + span,
.ns-form.outline label > input[type="month"]:active + span,
.ns-form.outline label > input[type="number"]:active + span,
.ns-form.outline label > input[type="password"]:active + span,
.ns-form.outline label > input[type="range"]:active + span,
.ns-form.outline label > input[type="search"]:active + span,
.ns-form.outline label > input[type="tel"]:active + span,
.ns-form.outline label > input[type="text"]:active + span,
.ns-form.outline label > input[type="time"]:active + span,
.ns-form.outline label > input[type="url"]:active + span,
.ns-form.outline label > input[type="week"]:active + span,
.ns-form.outline label > textarea:focus + span,
.ns-form.outline label > textarea:active + span,
.ns-form.outline label > select:focus + span,
.ns-form.outline label > select:active + span,
label.outline > input[type="color"]:focus + span,
label.outline > input[type="date"]:focus + span,
label.outline > input[type="datetime-local"]:focus + span,
label.outline > input[type="email"]:focus + span,
label.outline > input[type="file"]:focus + span,
label.outline > input[type="image"]:focus + span,
label.outline > input[type="month"]:focus + span,
label.outline > input[type="number"]:focus + span,
label.outline > input[type="password"]:focus + span,
label.outline > input[type="range"]:focus + span,
label.outline > input[type="search"]:focus + span,
label.outline > input[type="tel"]:focus + span,
label.outline > input[type="text"]:focus + span,
label.outline > input[type="time"]:focus + span,
label.outline > input[type="url"]:focus + span,
label.outline > input[type="week"]:focus + span,
label.outline > input[type="color"]:active + span,
label.outline > input[type="date"]:active + span,
label.outline > input[type="datetime-local"]:active + span,
label.outline > input[type="email"]:active + span,
label.outline > input[type="file"]:active + span,
label.outline > input[type="image"]:active + span,
label.outline > input[type="month"]:active + span,
label.outline > input[type="number"]:active + span,
label.outline > input[type="password"]:active + span,
label.outline > input[type="range"]:active + span,
label.outline > input[type="search"]:active + span,
label.outline > input[type="tel"]:active + span,
label.outline > input[type="text"]:active + span,
label.outline > input[type="time"]:active + span,
label.outline > input[type="url"]:active + span,
label.outline > input[type="week"]:active + span,
label.outline > textarea:focus + span,
label.outline > textarea:active + span,
label.outline > select:focus + span,
label.outline > select:active + span {
    border: var(--form-border-size) solid var(--form-color-activity);
    background: transparent;
}
label > input[type="color"]:not(:placeholder-shown):invalid + span,
label > input[type="date"]:not(:placeholder-shown):invalid + span,
label > input[type="datetime-local"]:not(:placeholder-shown):invalid + span,
label > input[type="email"]:not(:placeholder-shown):invalid + span,
label > input[type="file"]:not(:placeholder-shown):invalid + span,
label > input[type="image"]:not(:placeholder-shown):invalid + span,
label > input[type="month"]:not(:placeholder-shown):invalid + span,
label > input[type="number"]:not(:placeholder-shown):invalid + span,
label > input[type="password"]:not(:placeholder-shown):invalid + span,
label > input[type="range"]:not(:placeholder-shown):invalid + span,
label > input[type="search"]:not(:placeholder-shown):invalid + span,
label > input[type="tel"]:not(:placeholder-shown):invalid + span,
label > input[type="text"]:not(:placeholder-shown):invalid + span,
label > input[type="time"]:not(:placeholder-shown):invalid + span,
label > input[type="url"]:not(:placeholder-shown):invalid + span,
label > input[type="week"]:not(:placeholder-shown):invalid + span {
    color: var(--color-danger--font);
    border-bottom: var(--form-border-size) solid var(--color-danger--font);
}
label[data-error]:after {
    grid-area: extrainfo;
    width: 100%;
    height: auto;
    display: block;
    margin: calc(var(--form-tip-font-size) * 0.25) 0 0;
    content: attr(data-error);
    font-size: var(--form-tip-font-size);
    font-weight: 500;
    font-style: normal;
    color: var(--color-danger--font);
}
label > input[type="range"] {
    width: calc(100% - var(--spacer));
    height: calc(var(--form-height) - (var(--form-font-size) * var(--type-line-height) + calc(var(--spacer)/4)));
    margin: calc(var(--form-font-size) * var(--type-line-height) + calc(var(--spacer)/4)) calc(var(--spacer)/2) 0;
}
label > select {
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
}
label[data-tip]:after {
    grid-area: extrainfo;
    width: 100%;
    height: auto;
    display: block;
    margin: calc(var(--form-tip-font-size) * 0.25) 0 0;
    content: attr(data-tip);
    font-size: var(--form-tip-font-size);
    font-weight: var(--form-tip-font-weight);
    font-style: var(--form-tip-font-style);
    color: var(--form-tip-color);
}
label > input[type="checkbox"],
label > input[type="radio"] {
    grid-area: input;
    opacity: 0;
    position: absolute;
    z-index: 1;
}
label > input[type="checkbox"] + span,
label > input[type="radio"] + span {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
}
label > input[type="checkbox"]:hover,
label > input[type="radio"]:hover,
label > input[type="checkbox"] + span:hover,
label > input[type="radio"] + span:hover {
    cursor: pointer;
}
label > input[type="checkbox"] + span:before {
    width: var(--form-checkbox-size);
    min-width: var(--form-checkbox-size);
    max-width: var(--form-checkbox-size);
    height: var(--form-checkbox-size);
    display: block;
    content: "";
    flex-grow: 1;
    margin: 0 calc(var(--spacer)/4) 0 0;
    border: var(--form-checkbox-thickness) solid var(--form-color);
    border-radius: var(--form-checkbox-radius);
}
label > input[type="checkbox"]:checked + span:after {
    width: calc(var(--form-checkbox-size) * 0.75);
    min-width: calc(var(--form-checkbox-size) * 0.75);
    max-width: calc(var(--form-checkbox-size) * 0.75);
    height: calc(var(--form-checkbox-size) * 0.75 * 0.6);
    display: block;
    content: "";
    position: absolute;
    top: calc( (var(--form-checkbox-size) - (var(--form-checkbox-size) * 0.874642784))/2 + (var(--spacer)/4) + (var(--form-checkbox-mark-thickness)/1.5));
    left: calc( (var(--form-checkbox-size) - (var(--form-checkbox-size) * 0.874642784))/2 + (var(--form-checkbox-mark-thickness)/2));
    border-bottom: var(--form-checkbox-mark-thickness) solid var(--form-checkbox-mark-color);
    border-left: var(--form-checkbox-mark-thickness) solid var(--form-checkbox-mark-color);
    transform: rotate(-45deg);
}
label > input[type="checkbox"]:checked + span:before {
    background: var(--form-checkbox-selected-color);
    border: 1px solid var(--form-checkbox-selected-color);
}
label > input[type="radio"] + span:before {
    width: var(--form-radio-size);
    min-width: var(--form-radio-size);
    max-width: var(--form-radio-size);
    height: var(--form-radio-size);
    display: block;
    content: "";
    flex-grow: 1;
    margin: 0 calc(var(--spacer)/4) 0 0;
    border: 1px solid var(--form-color);
    border-radius: 50%;
}
label > input[type="radio"]:checked + span:after {
    width: calc(var(--form-radio-mark-size) - var(--form-radio-thickness));
    height: calc(var(--form-radio-mark-size) - var(--form-radio-thickness));
    display: block;
    content: "";
    position: absolute;
    top: calc((var(--spacer)/4 + (var(--form-radio-size) - var(--form-radio-mark-size))/2) + var(--form-radio-thickness)/2);
    left: calc((var(--form-radio-size) - var(--form-radio-mark-size))/2 + var(--form-radio-thickness)/2);
    background: var(--form-radio-mark-color);
    border-radius: 50%;
}
label > input[type="radio"]:checked + span:before {
    background: var(--color-lightest);
    border: var(--form-radio-thickness) solid var(--form-radio-mark-color);
}
label > input[required] + span:after,
label > textarea[required] + span:after,
label > select[required] + span:after {
    display: inline;
    content: "*";
}
label > .icon {
    width: calc(var(--form-icon-size) * var(--line-height));
    height: calc(var(--form-icon-size) * var(--line-height));
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc((var(--form-height) - calc(var(--form-icon-size) * var(--type-line-height)))/2);
    left: calc(var(--spacer)/2);
    font-size: var(--form-icon-size);
    color: var(--form-color);
    text-align: center;
    vertical-align: middle;
    z-index: 2;
}
label > .icon > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
label > .icon ~ input,
label > .icon ~ input ~ span:not([class]) {
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
}
.form-group {
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
}
.form-group > span {
    grid-column: 1/-1;
    width: 100%;
    display: block;
    margin: 0 0 calc(var(--spacer)/2);
    font-size: var(--form-question-font-size);
    font-weight: var(--form-question-font-weight);
}
.form-group.inline {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: var(--spacer);
}
.form-group.inline > span {
    margin: 0;
}
.form-group.inline[data-cols="1"] {
    grid-template-columns: repeat(1,1fr);
}
.form-group.inline[data-cols="2"] {
    grid-template-columns: repeat(2,1fr);
}
.form-group.inline[data-cols="3"] {
    grid-template-columns: repeat(3,1fr);
}
.form-group.inline[data-cols="4"] {
    grid-template-columns: repeat(4,1fr);
}
.form-group.inline[data-cols="5"] {
    grid-template-columns: repeat(5,1fr);
}
.form-group.inline[data-cols="6"] {
    grid-template-columns: repeat(6,1fr);
}
.form-group.inline[data-cols="7"] {
    grid-template-columns: repeat(7,1fr);
}
.form-group.inline[data-cols="8"] {
    grid-template-columns: repeat(8,1fr);
}
.form-group.inline[data-cols="9"] {
    grid-template-columns: repeat(9,1fr);
}
.form-group.inline[data-cols="10"] {
    grid-template-columns: repeat(10,1fr);
}
.form-group.inline[data-cols="11"] {
    grid-template-columns: repeat(11,1fr);
}
.form-group.inline[data-cols="12"] {
    grid-template-columns: repeat(10,1fr);
}
.ns-submit {
    grid-column: 1/-1;
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    display: flex;
    justify-content: center;
    align-items: center;
}
.ns-form .ns-submit > .ns-btn,
.ns-form .ns-submit > button,
.ns-form .ns-submit > input[type="submit"],
.ns-form .ns-submit > input[type="reset"] {
    margin: calc(var(--spacer)/2);
}