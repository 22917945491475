.ns-menu-disciplinas {
    width: auto;
    display: block;
    position: relative;
    border: var(--menu-border-thickness) solid var(--menu-border-color);
    border-radius: var(--menu-border-radius);
    background: var(--menu-background);
    overflow: auto;
    z-index: var(--z-menu);
}
.ns-menu-disciplinas ul li.divider {
    border-bottom: var(--menu-border-thickness) solid var(--menu-border-color);
}
.ns-menu-disciplinas ul li a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
}
.ns-menu-disciplinas ul li a:hover,
.ns-menu-disciplinas ul li a:hover * {
    filter: none;
    color: var(--menu-font-color--hover);
    background: var(--menu-background--hover);
}
.ns-menu-disciplinas ul li a:hover * {
    background: transparent;
}
.ns-menu-disciplinas ul li a [class*="mdi"] {
    width: var(--menu-icon-size);
    height: var(--menu-icon-size);
    font-size: var(--menu-icon-mdi);
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.ns-menu-disciplinas ul li a img {
    width: var(--menu-icon-size);
    height: var(--menu-icon-size);
    object-fit: cover;
    object-position: center;
}
