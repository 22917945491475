.ns-carousel {
    width: 100%;
    height: auto;
}
.ns-carousel .slick-arrow,
.ns-carousel .slick-dots {
    display: none !important;
}
.ns-carousel[data-height] .ns-carousel-wrapper .slick-track,
.ns-carousel[data-height] .ns-carousel-wrapper .slick-track .slick-slide,
.ns-carousel[data-height] .ns-carousel-wrapper .slick-track .slick-slide > div,
.ns-carousel[data-height] .ns-carousel-wrapper .slick-track .slick-slide .ns-item,
.ns-carousel[data-height] .ns-carousel-wrapper .slick-track .slick-slide .ns-item > div {
    height: inherit;
}
.ns-carousel .ns-item,
.ns-carousel .ns-item img {
    width: 100%;
    height: auto;
}
.ns-carousel a.ns-item:hover {
    filter: none;
}
.ns-carousel[data-height] .ns-item img {
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.ns-carousel .ns-carousel-wrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "carousel carousel carousel";
    column-gap: var(--spacer);
    row-gap: calc(var(--spacer)/2);
}
.ns-carousel.nav .ns-carousel-wrapper {
    grid-template-areas: "prev carousel next";
}
.ns-carousel.dots .ns-carousel-wrapper {
    grid-template-areas: "carousel carousel carousel" "dots dots dots";
}
.ns-carousel.nav.dots .ns-carousel-wrapper {
    grid-template-areas: "prev carousel next" "dots dots dots";
}
.ns-carousel.nav-over.dots .ns-carousel-wrapper {
    grid-template-areas: "carousel carousel carousel" "dots dots dots";
}
.ns-carousel.nav.dots-over .ns-carousel-wrapper {
    grid-template-areas: "prev carousel next";
}
.ns-carousel.nav-over.dots-over .ns-carousel-wrapper {
    grid-template-areas: "carousel carousel carousel";
}