.docs-mainmenu {
    width: 100%;
    height: 48px;
    background: blue;   
    position: fixed;
    top: 0;
    left: 0; 
    z-index: var(--z-mainmenu);
}
.docs-mainmenu .title {
    padding: 0 var(--spacer) 0 0;
    color: var(--color-lightest);
    font-weight: 500;
    text-transform: uppercase;
}
.docs-mainmenu > .ns-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}
.docs-mainmenu ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}
.docs-mainmenu ul li {
    margin: 0;
    padding: 0;
}
.docs-mainmenu ul li a {
    display: block;
    margin: 0 var(--spacer);
    color: var(--color-lightest);
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
}