.ips-header {
    width: 100%;
    height: var(--header-height);
    border-bottom: 1px solid var(--color-gray-lighter);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ips-header > .ns-container {
    height: var(--header-height);
    display: flex;
    padding: 0;
    align-items: center;

}
.ips-header .ns-dropdown > a {
    text-decoration: none;
}
.ips-header h1,
.ips-header .ns-dropdown > a span {
    margin: 0 0.5rem 0 0;
    font-size: 1.375rem;
    font-weight: 400;
    color: var(--color-darkest);
}