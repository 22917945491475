button,
input[type="button"],
input[type="reset"],
input[type="submit"],
[class*=ns-btn] {
    width: auto;
    height: var(--button-height);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: var(--margin-top) var(--margin-right) var(--margin-bottom) var(--margin-left);
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    font-size: var(--button-type-size);
    font-weight: var(--button-type-weight);
    color: var(--button-type-color);
    text-transform: var(--button-text-transform);
    text-decoration: none;
    border-radius: var(--button-border-radius);
    background: var(--button-background);
    overflow: hidden;
    letter-spacing: var(--button-letter-spacing);
    filter: none !important;
    vertical-align: middle;
    transition: var(--animation);
}
.ns-btn-link:hover {
    filter:brightness(0.6) !important;
}
[class*=ns-btn-full] {
    width: 100%;
    display: flex;
}
button > span,
[class*=ns-btn] > span {
    display: block;
    position: relative;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    letter-spacing: inherit;
    line-height:  var(--button-line-height);
    text-transform: inherit;
    text-decoration: none;
    letter-spacing: var(--button-letter-spacing);
    z-index: 3;
}
button:before,
[class*=ns-btn]:before {
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    position: absolute;
    top: var(--button-height);
    left: 0;
    z-index: 1;
    background: var(--button-background--hover);
    transition: var(--animation);
}
button:after,
[class*=ns-btn]:after {
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: rgba(0,0,0,0.2);
    z-index: 2;
    transition: var(--animation);
}
button:hover:before,
[class*=ns-btn]:hover:before {
    top: 0px;
}
button:hover,
[class*=ns-btn]:hover {
    cursor: pointer;
    color: var(--button-type-color--hover);
}
button[disabled],
[class*=ns-btn][disabled] {
    opacity: 0.5 !important;
}
button[disabled]:hover,
[class*=ns-btn][disabled]:hover,
button[disabled]:hover *,
[class*=ns-btn][disabled]:hover * {
    cursor: not-allowed !important;
}
button:active:after,
[class*=ns-btn]:active:after {
    opacity: 1;
}
button[disabled]:active:after,
[class*=ns-btn][disabled]:active:after {
    opacity: 0 !important;
}
button:not(.ns-btn-icon) [class*=mdi] 
[class*=ns-btn]:not(.ns-btn-icon) [class*=mdi] {
    font-size: calc(var(--button-type-size) * 1.5)
}
.ns-btn-icon {
    width: var(--button-height);
    font-size: 2rem;
}