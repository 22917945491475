.ns-table {
    counter-reset: cell;
}
.ns-table table {
    width: 100%;
    border: var(--table-border-outside-thickness) solid var(--table-border-outside-color);
    border-left: none;
    border-right: none;
}
.ns-table table * {
    vertical-align: top;
    text-align: left;
}
.ns-table table .left {
    text-align: left;
}
.ns-table table .center {
    text-align: center;
}
.ns-table table .right {
    text-align: right;
}
.ns-table table .justify {
    text-align: justify;
}
.ns-table table .top {
    vertical-align: top;
}
.ns-table table .middle {
    vertical-align: middle;
}
.ns-table table .nowrap {
    white-space: nowrap;
}
.ns-table table .bottom {
    vertical-align: bottom;
}
.ns-table table .title {
    font-weight: var(--table-font-header-weight);
}
.ns-table table .medium {
    font-weight: 500;
}
.ns-table table .bold {
    font-weight: 700;
}
.ns-table table thead * {
    font-weight: var(--table-font-header-weight);
    color: var(--table-font-header-color);
    text-align: left;
    text-decoration: none;
}
.ns-table table thead th {
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    vertical-align: middle;
    border-bottom: var(--table-border-header-thickness) solid var(--table-border-header-color);
    background: var(--table-background-header);
}
.ns-table table thead th:nth-of-type(1) label input + span,
.ns-table table tbody td:nth-of-type(1) label input + span {
    width: var(--form-checkbox-size);
    min-width: var(--form-checkbox-size);
    max-width: var(--form-checkbox-size);
    height: var(--form-checkbox-size);
    display: inline-block;
    color: transparent;
    overflow: hidden;
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
}
.ns-table table thead th:nth-of-type(1) label input + span:after {
    top: calc( (var(--form-checkbox-size) - (var(--form-checkbox-size) * 0.874642784))/2 + (var(--form-checkbox-mark-thickness)/1.5));
}
.ns-table table tbody * {
    font-weight: var(--table-font-weight);
    color: var(--table-font-color);
    text-align: left;
}
.ns-table table tbody tr:nth-of-type(odd) td {
    background: var(--table-background-odd);
}
.ns-table table tbody tr:nth-of-type(even) td {
    background: var(--table-background-even);
}
.ns-table table tbody tr:hover td {
    background: var(--table-background-hover);
}
.ns-table table tbody td {  
    vertical-align: top;
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    border-bottom: var(--table-border-thickness) solid var(--table-border-color);
}
.ns-table table tbody a {
    color: var(--color-link);
    text-decoration: none;
}
.ns-table table tbody tr:last-of-type td {
    border-bottom: none;
} 
.ns-table table tbody td:nth-of-type(1) label input + span:after {
    top: calc( (var(--form-checkbox-size) - (var(--form-checkbox-size) * 0.874642784))/2 + (var(--form-checkbox-mark-thickness)/1.5));
}
.ns-table table tfoot * {
    font-weight: var(--table-font-footer-weight);
    color: var(--table-font-footer-color);
    text-align: left;
}
.ns-table table tfoot td { 
    vertical-align: middle;
    border-top: var(--table-border-footer-thickness) solid var(--table-border-footer-color);
}
.ns-table table caption {
    text-align: center;
}