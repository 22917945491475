.ips-communitylist--done header {
    width: 100%;
    height: var(--box-header);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top-left-radius: var(--base-radius);
    border-top-right-radius: var(--base-radius);
    background: url("/alunos/data/clients/1/images/disciplinas--header.jpg") right center no-repeat;
    background-size: cover;
}
.ips-communitylist--done header h2 {
    margin: 0;
    padding: 0 var(--spacer);
    font-size: 1.375rem;
    font-weight: 500;
    color: var(--color-lightest);
}
.ips-communitylist--done ul {
    margin: 0;
    padding: var(--spacer) 0;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: var(--spacer);
}
.ips-communitylist--done ul li {
    display: block;
    padding: 0;
    margin: 0 ;
    border-radius: var(--base-radius);
    border: 1px solid var(--color-gray-lighter);
}
.ips-communitylist--done ul li .image {
    width: 100%;
    height: 0;
    padding: 0 0 40%;
    position: relative;
    border-top-left-radius: var(--base-radius);
    border-top-right-radius: var(--base-radius);
}
.ips-communitylist--done ul li .image img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
}
.ips-communitylist--done ul li .title {
    display: block;
    font-size: 1;
    font-weight: 500;
    padding: var(--spacer);
    color: var(--color-darkest);
}
.ips-communitylist--done ul li .progress {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: "graph graph" "status action";
    gap: var(--spacer);
    padding: 0 var(--spacer) var(--spacer);
}
.ips-communitylist--done ul li .progress .graph {
    grid-area: graph;
    width: 100%;
    height: 4px;
    display: block;
    border-radius: 2px;
    background: var(--color-success);
}
.ips-communitylist--done ul li .progress .info {
    grid-area: status;
    align-self: center;
    color: var(--color-success);
}
@media (max-width: 1200px) {
    .ips-communitylist--done ul {
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width: 992px) {
    .ips-communitylist--done ul {
        grid-template-columns: 1fr;
    }

}