.ns-dropdown {
    position: relative;
    z-index: var(--z-menu);
}
.ns-dropdown > a {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
}
.ns-dropdown a > * {
    margin: 0 calc(var(--spacer)/4) 0 0;
}
.ns-dropdown > a:after {
    width: var(--dropdown-chevron-size);
    height: var(--dropdown-chevron-size);
    display: block;
    content: "";
    margin-top: calc(0px - (var(--dropdown-chevron-size)/4));
    border-right: var(--dropdown-chevron-thickness) solid var(--dropdown-chevron-color);
    border-bottom: var(--dropdown-chevron-thickness) solid var(--dropdown-chevron-color);  
    transform: rotate(45deg)  
}
.ns-dropdown .ns-menu {
    display: none;
    position: absolute;
    z-index: calc(var(--z-menu) + 10);
}
.ns-dropdown .ns-menu-disciplinas {
    display: none;
    position: absolute;
    z-index: calc(var(--z-menu) + 10);
}