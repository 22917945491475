/* Referências */
figure * {
    text-align: center;
}
figure figcaption {
    text-align: center;
}
/* Imagens e Vídeos */
picture,
.ns-image,
video,
.ns-video {
    height: auto;
    display: block;
    position: relative;
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
}
picture.square,
.ns-image.square,
picture.rectangle,
.ns-image.rectangle,
picture.wide,
.ns-image.wide,
picture.rectangle.portrait,
.ns-image.rectangle.portrait,
picture.wide.portrait,
.ns-image.wide.portrait,
video.square,
.ns-video.square,
video.rectangle,
.ns-video.rectangle,
video.wide,
.ns-video.wide ,
video.rectangle.portrait,
.ns-video.rectangle.portrait,
video.wide.portrait,
.ns-video.wide.portrait {
    height: 0;
}

picture img,
.ns-image img,
video > *,
.ns-video > * {
    width: 100%;
}
picture img, .ns-image img,
video > *, .ns-video > * {
    display: block;
    height: auto;
    position: relative;
}
picture.square img,
.ns-image.square img,
picture.rectangle img,
.ns-image.rectangle img,
picture.wide img,
.ns-image.wide img,
video.square > *,
.ns-video.square > *,
video.rectangle > *,
.ns-video.rectangle > *,
video.wide > *,
.ns-video.wide > *  {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
}