.ips-communitylist--simple header {
    width: 100%;
    height: var(--box-header);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top-left-radius: var(--base-radius);
    border-top-right-radius: var(--base-radius);
    background: url("/alunos/data/clients/1/images/disciplinas--header.jpg") right center no-repeat;
    background-size: cover;
}
.ips-communitylist--simple header h2 {
    margin: 0;
    padding: 0 var(--spacer);
    font-size: 1.375rem;
    font-weight: 500;
    color: var(--color-lightest);
}
.ips-communitylist--simple ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.ips-communitylist--simple ul li {
    min-height: 112px;
    display: grid;
    grid-template-columns: 1fr 200px;
    padding: var(--spacer);
    margin: 0 0 calc(var(--spacer) * 0.5);
    background: var(--color-primary-light);
    gap: var(--spacer);
}

.ips-communitylist--simple ul li.notFound
{
    
    width: 100%;
    grid-template-columns: 1fr;
    padding: 20px;
    border: 0px;
    min-height: 40px;
    vertical-align: middle;
}
.ips-communitylist--simple ul li.notFound p
{
    text-align: center;
}
.ips-communitylist--simple ul li > a {
    flex-grow: 1;
    min-width: 120px;
}
.ips-communitylist--simple ul li .content {
    align-self: stretch;
    margin: 0 calc(var(--spacer) * 1.5) 0 0;    
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}
.ips-communitylist--simple ul li .content .title {
    display: block;
    font-size: 1.375rem;
    color: var(--color-darkest);
}
.ips-communitylist--simple ul li .content .description {
    display: block;
    margin: 0.5rem 0 0;
    font-size: 0.875rem;
    font-weight: 300;
    color: var(--color-gray-darker);
}

@media(max-width: 799px) {
    .ips-communitylist--simple ul li {
        grid-template-columns: 100%;
    }
}