/* Sidemenu */
.ips-note--pad {
    width: calc(100% - 20px);
    transition: all 0.3s linear;
    max-width: 674px;
    height: 100vh;
    position: fixed;
    top: 0px;
    right: -190%;
    padding: 20px 20px;
    background: #fff;
    overflow: auto;
    z-index: 10032;
    visibility: visible;
    -webkit-box-shadow: 0px 20px 35px 0px rgba(0,0,0,0.16);
       -moz-box-shadow: 0px 20px 35px 0px rgba(0,0,0,0.16);
            box-shadow: 0px 20px 35px 0px rgba(0,0,0,0.16);
}

.ips-note--pad header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}

.ips-note--pad .current-note,
.ips-note--pad .notepad-table {
    margin: 15px 0px;
}
.ips-note--pad .current-notepad-edit,
.ips-note--pad .edit-notepad
{
    display: flex;
    justify-content: row-reverse;
    justify-content: space-between;
}

.ips-note--pad.show {
    right: 0;
}
.ips-note--pad .pb-sidemenu--lp {
    margin: 40px 0 0;
}
.ips-note--pad .dataTables_length label,
.ips-note--pad .dataTables_filter label {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ips-note--pad .dataTables_length select {
    height: 30px;
}

.ips-note--pad .add-notepad {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    text-align: center;
}

.ips-note--pad .save-notepad {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.ips-note--pad .ck-editor__editable {
    min-height: 300px;
}
.ips-note--pad .notepad tbody th {
    padding: 10px 18px;
}
.ips-note--pad .save-note > div,
.ips-note--pad .view-note div,
.ips-note--pad .edit-note div {
    margin-bottom: 10px;
}

.ips-note--pad .notepad-title {
    font-weight: bold;
}

.ips-note--pad .no-data {
    text-align: center;
    margin-top: 10px;
}

.ips-note--pad .view-note #note {
    height: auto;
    min-height: 300px;
    width: 100%;
    border: solid 1px #b3b0b0;
    background-color: whitesmoke;
    padding: 10px;
}
/* Matte */
.ips-matte {
    width: 100vw;
    height: 100vh;
    display: none;
    content: "";
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10031;
}

body.showModal .ips-matte {
    display: block;
}



aside.ips-note--pad h2{
    margin: 4px;
    font-size: 22px;
}
#pb-sidemenu--lp{
    border: 1px solid silver;
    margin-top: 30px;
    padding: 15px;
    border-radius: 6px;
    background-color: #f7f7f7;
}

aside.ips-note--padtable.dataTable thead th, table.dataTable thead td
{
    border-top: 1px solid silver;
}
aside.ips-note--pad .dataTables_info
{
    font-size: 13px;
}
.ips-note--pad .dataTables_length label,
.ips-note--pad .dataTables_length select option,
.ips-note--pad .dataTables_filter label,
.ips-note--pad .notepad tbody th,
.ips-note--pad .notepad tbody td
{
    font-size: 14px;
}
.ips-note--pad #DataTables_Table_0_paginate > span > a
{
    padding: 4px;
}