.ips-classes--aside {
    border-radius: var(--base-radius);
    background: var(--color-primary-dark);
}
.ips-classes--aside header {
    width: 100%;
    height: var(--box-header);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacer);    
    border-bottom: 1px solid var(--color-lightest);
}
.ips-classes--aside header .title {
    margin: 0;
    padding: 0 var(--spacer) 0 0;
    font-size: 1.375rem;
    font-weight: 400;
    color: var(--color-lightest);
}
.ips-classes--aside .group {
    margin: var(--spacer) 0;
    padding: 0 var(--spacer);
}
.ips-classes--aside .group .title {
    display: block;
    margin: 0 0 8px;
    padding: 0;
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--color-lightest);
}
.ips-classes--aside ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.ips-classes--aside ul li {
    margin: 0 0 4px;
}
.ips-classes--aside ul li a {
    text-decoration: none;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
}
.ips-classes--aside ul li a span {
    font-size: 0.875rem;
    color: var(--color-lightest);
}
.ips-classes--aside ul li a img {
    margin: 0 10px 0 0;
}
.ips-classes--aside .footer {
    margin: calc(var(--spacer) * 2.5) 0 0;
    padding: 0 var(--spacer) var(--spacer);
}
.ips-classes--aside .footer.nobody {
    margin: var(--spacer) 0 0;
}